import './css/cards.css'
import { useState, useEffect } from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, TextCardTitle, TextCardSubtitle, TextToolbar, TextRegSub, TextRF, TextRFSub } from './index'
import {
    faDollarSign, faMapMarkerAlt, faDesktop, faUser, faCalendarDay, faCommentAlt, faDotCircle, faTimes, faDownload, faSyncAlt, faThLarge,
    faList, faCaretLeft, faSchool, faBuilding, faCheckCircle, faChair, faCrosshairs, faTimesCircle, faGlobe, faDoorOpen, faBus
} from '@fortawesome/free-solid-svg-icons'
import { SwitchTransition, CSSTransition } from "react-transition-group";
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import { Spinner } from 'react-bootstrap'
import { downloadFile } from '../Api/index';
import { Fade, Pages, TabBar, goTop } from '../Components'
import { faFacebookF, faInstagram, faLinkedin, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { CurrentJobs, TerminatedJobs } from '../Admin/employmentTabs'

export function Card(props) {
    const { onClick, data } = props
    const { icon, title, subTitle } = data
    return (
        <div className='card' onClick={onClick} >
            <i><FontAwesomeIcon icon={icon} style={{ fontSize: '34px', marginBottom: '7%' }} /></i>
            <h3 className='card-title' >
                {title && title}
            </h3>
            <h5 className='card-subtitle'  >
                {subTitle && subTitle}
            </h5>
        </div>
    )
}

export function ServicesCard(props) {
    const { onClick, data } = props
    const { icon, title, subTitle } = data

    return (

        <div className='serv-card' onClick={onClick} >
            <Row>
                <Col xs='2'>
                    <i><FontAwesomeIcon icon={icon} style={{ marginBottom: 7 }} /></i>
                </Col>
                <Col>
                    <div className='serv-card-title' >
                        {title && title}
                    </div>

                    <div className='serv-card-subtitle'  >
                        {subTitle && subTitle}
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export function AdviceCard(props) {
    const { data, lang, page } = props

    return (
        <SwitchTransition mode={'out-in'}>
            <CSSTransition
                key={page}
                addEndListener={(node, done) => {
                    node.addEventListener("transitionend", done, false);
                }}
                classNames="fade"
            >
                <Row>
                    {data.map(advice => {
                        const { title, subtitle, img, name, date } = advice
                        return (
                            <Col lg='4' md='12' xs='12' className='p-2'>
                                <div className='advice-card-container'>
                                    <div className='advice-card-img'>
                                        <img src={img} alt={''} loading="lazy" />
                                    </div>
                                    <div className='advice-card-content'>
                                        <Row>
                                            <div xs='1' sm='1' md='1' lg='1' >
                                                <FontAwesomeIcon style={{ marginRight: 10, marginLeft: 10 }} color='#ea6969' icon={faCalendarDay} />

                                            </div>
                                            <div className='advice-card-date'>

                                                {lang.publishDate} {date}
                                            </div>
                                        </Row>
                                        <div className='advice-card-title'>
                                            {title}
                                        </div>
                                        <div className='advice-card-subtitle'>
                                            {subtitle}
                                        </div>
                                    </div>
                                    <div className='advice-card-footer'>
                                        <div className='d-flex align-items-center'>

                                            <img className='advice-card-footer-image' src={img} alt={''} loading="lazy"/>
                                            {name}
                                        </div>

                                        <FontAwesomeIcon icon={faCommentAlt} color='#ea6969' size='sm' />
                                    </div>
                                </div>
                            </Col>
                        )
                    })}
                </Row>
            </CSSTransition>
        </SwitchTransition>
    )
}

export function JobCard(props) {
    const [view, setView] = useState('list')
    const [currentPage, setPage] = useState(1)
    const [start, setStart] = useState(1)
    const [end, setEnd] = useState(1)
    const { data, lang, jobPage, } = props
    const Page = (data) => {
        setPage(data)
    }
    return (
        <Container  >
            <Row className='d-flex justify-content-sm-center justify-content-md-between align-items-center  justify-content-center job-card-header-panel mb-3'>
                <Col className='mt-3'  >
                    <Pages
                        pageSize={10}
                        maxPages={3}
                        total={data && data.length}
                        setPage={Page}
                        currentPage={currentPage}
                        start={setStart}
                        end={setEnd}
                    />
                </Col>
                <Col xs='auto' >
                    <Row >
                        <Col xs='6' >
                            <i>
                                <FontAwesomeIcon onClick={() => setView('grid')} size='sm' icon={faThLarge} color={view === 'grid' ? '#23c0e9' : '#797979'} />
                            </i>

                        </Col>
                        <Col xs='6'>
                            <FontAwesomeIcon onClick={() => setView('list')} size='sm' icon={faList} color={view === 'list' ? '#23c0e9' : '#797979'} />
                        </Col>
                    </Row>
                </Col>
                {/* <Col xs='5'>
                    <TextRegSub  >{lang.jobView} <span style={{ color: '#5fc0e9' }}>{'1-20'} </span>{lang.jobView2}</TextRegSub>
                </Col> */}
            </Row>
            <Fade nav={currentPage} >
                <Row className='job-card-container' >
                    {data && data.slice(start, end).map(job => {
                        const { img, jobTitle, companyId, minSalary, maxSalary, workType, keywords, status } = job
                        return (
                            <Col style={{ paddingRight: 20, paddingLeft: 20, paddingBottom: 10 }} xs='12' sm='12' md='12' lg={view === 'grid' ? 6 : 12} className='job-card' >
                                <div className='job-card-label'>
                                    {workType}
                                </div>
                                <Row className='job-card-header p-3 d-flex align-items-center'  >
                                    <Col md='12' xs='12' sm='12' lg='auto' >
                                        {view === 'grid' && <br />}
                                        <img style={{
                                            width: view === 'grid' ? '70px' : '120px',
                                            height: view === 'grid' ? '70px' : '120px',
                                            borderRadius: '15px',
                                        }} src={lang.baseUrl + '/media/imgs/' + img} alt={''} loading="lazy"/>
                                    </Col>
                                    <Col lg='8' md='12' xs='12' sm='12' >
                                        {view === 'grid' && <br />}
                                        <TextCardTitle align="right" >
                                            {jobTitle}
                                        </TextCardTitle>
                                        <TextCardSubtitle color='#5fc0e9' align="right">
                                            {companyId && companyId.name}
                                        </TextCardSubtitle>
                                        {view === 'list' && <>

                                            <TextCardSubtitle color='#000000' align="right">
                                                <a href={companyId && companyId.location} target="_blank">
                                                    <i ><FontAwesomeIcon icon={faMapMarkerAlt} size='md' color='#ff4136' /></i> {companyId && companyId.address}
                                                </a>
                                            </TextCardSubtitle>

                                            <Row  >
                                                <Col xs='12' sm='12' md='auto'  >
                                                    <TextCardSubtitle color='#000000' align="right">
                                                        <i><FontAwesomeIcon icon={faDollarSign} size='md' color='#ff4136' /></i> {lang.salary} {minSalary} {':'} {maxSalary} {lang.pound}
                                                    </TextCardSubtitle>
                                                </Col>

                                                <Col  >
                                                    {status ?
                                                        <Button onClick={() => jobPage(job)} size='sm' fullWidth text={lang.applied} />
                                                        :
                                                        <Button onClick={() => jobPage(job)} type='secondary' size='sm' fullWidth text={lang.apply} />
                                                    }
                                                </Col>
                                            </Row>


                                        </>
                                        }
                                    </Col>
                                    <Col className='m-2'>
                                        {view === 'grid' && <>
                                            <Row >
                                                <Col xs='12' sm='12' md='auto' >
                                                    <TextCardSubtitle color='#000000' align="right">
                                                        <i><FontAwesomeIcon icon={faDollarSign} size='md' color='#ff4136' /></i> {lang.salary} {minSalary} {':'} {maxSalary} {lang.pound}
                                                    </TextCardSubtitle>
                                                </Col>
                                                <Col xs='12' sm='12' md='auto' >
                                                    <TextCardSubtitle color='#000000' align="right">
                                                        <a href={companyId && companyId.location} target="_blank">
                                                            <i ><FontAwesomeIcon icon={faMapMarkerAlt} size='md' color='#ff4136' /></i> {companyId && companyId.address}
                                                        </a>
                                                    </TextCardSubtitle>
                                                </Col>
                                            </Row>
                                            {status ?
                                                <Button onClick={() => jobPage(job)} margin size='sm' fullWidth text={lang.applied} />
                                                :
                                                <Button onClick={() => jobPage(job)} margin type='secondary' size='sm' fullWidth text={lang.apply} />
                                            }
                                        </>
                                        }
                                    </Col>
                                </Row>


                                <Row className='job-card-footer' >
                                    <p className='job-card-keywords-title font-weight-bold'>
                                        {lang.keywords + ':'}
                                    </p>
                                    {keywords && keywords.map((key, i) => (
                                        <span className='job-card-keywords-sub' >
                                            {'\u00A0' + key + (i < keywords.length - 1 ? ',\u00A0' : '')}
                                        </span>
                                    ))}
                                </Row>
                            </Col>
                        )
                    })}
                </Row>
            </Fade>


        </Container>
    )
}
function GoogleMapsURLToEmbedURL(GoogleMapsURL) {
    var coords = /\@([0-9\.\,\-a-zA-Z]*)/.exec(GoogleMapsURL);
    if (coords != null) {
        var coordsArray = coords[1].split(',');
        return "https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d20000!2d" + coordsArray[1] + "!3d" + coordsArray[0] + "!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2suk!4v1486486434098";
    }
}

export function JobDetailsCard(props) {
    const { job, lang } = props
    return (
        <Container  >
            <Row className='job-card-container' >
                <Col xs='12' className='job-card' >
                    <div className='job-details-card-label'>
                        {job.workType}
                    </div>
                    <Row className='job-details-card-header' >
                        <Col>
                            <TextToolbar black margin>
                                {lang.aboutJob}
                            </TextToolbar>
                            <TextRegSub right>
                                {job.about}
                            </TextRegSub>
                            <TextToolbar black margin>
                                {lang.responsibilities}
                            </TextToolbar>
                            {job.responsibilities.map(value =>
                                <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', padding: 5, }}>
                                    <FontAwesomeIcon style={{ marginLeft: 7 }} size='2x' icon={faCaretLeft} color='#ea6969' />
                                    <TextRegSub right>
                                        {value}
                                    </TextRegSub>
                                </div>
                            )}
                            <TextToolbar black margin>
                                {lang.reqSkills}
                            </TextToolbar>
                            {job.skills.map(value =>
                                <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', padding: 5, }}>
                                    <FontAwesomeIcon style={{ marginLeft: 7 }} size='2x' icon={faCaretLeft} color='#ea6969' />
                                    <TextRegSub right>
                                        {value}
                                    </TextRegSub>
                                </div>
                            )}
                            <TextToolbar black margin>
                                {lang.appSteps}
                            </TextToolbar>
                            {job.procedures.map(value =>
                                <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', padding: 5, }}>
                                    <FontAwesomeIcon style={{ marginLeft: 7 }} size='2x' icon={faCaretLeft} color='#ea6969' />
                                    <TextRegSub right>
                                        {value}
                                    </TextRegSub>
                                </div>
                            )}
                            <TextToolbar black margin>
                                {lang.location}
                            </TextToolbar>
                            <div>
                                <iframe scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas"
                                    src={GoogleMapsURLToEmbedURL(job.companyId.location)}
                                    width="100%" height="350" frameborder="0" loading="lazy" ></iframe>


                            </div>

                        </Col>
                    </Row>

                    <Row className='job-card-footer' >
                        <p className='job-card-keywords-title font-weight-bold'>
                            {lang.keywords + ':'}
                        </p>
                        {job.keywords && job.keywords.map((key, i) => (
                            <span className='job-card-keywords-sub' >
                                {'\u00A0' + key + (i < job.keywords.length - 1 ? ',\u00A0' : '')}
                            </span>
                        ))}
                    </Row>
                </Col>

            </Row>


        </Container>
    )
}

export function ServicesPageCard(props) {
    const [currentPage, setPage] = useState(1)
    const [start, setStart] = useState(1)
    const [end, setEnd] = useState(1)
    const { data, lang, servicePage } = props
    const Page = (value) => {
        setPage(value)
    }
    return (
        <Container  >
            <Row className='d-flex justify-content-center align-items-center job-card-header-panel mb-3'>
                <Col className='mt-3' xs='auto' >
                    <Pages
                        pageSize={10}
                        maxPages={3}
                        total={data && data.length}
                        setPage={Page}
                        currentPage={currentPage}
                        start={setStart}
                        end={setEnd}
                    />
                </Col>
            </Row>
            <Fade nav={currentPage} >
                <Row className='job-card-container' >
                    {data.length > 0 && data.slice(start, end).map((service, index) => {
                        const { img, name, organizationId, amount, type, status, keywords } = service
                        return (
                            <Col key={index} style={{ paddingRight: 20, paddingLeft: 20, paddingBottom: 10 }} xs='12' sm='12' md='12' className='job-card' >
                                <div className='job-card-label'>
                                    {type}
                                </div>
                                <Row className='job-card-header p-3 d-flex align-items-center'  >
                                    <Col md='12' xs='12' sm='12' lg='auto' >
                                        <img style={{
                                            width: '120px',
                                            height: '120px',
                                            borderRadius: '15px',
                                        }} src={lang.baseUrl + '/media/imgs/' + img} alt={''} loading="lazy"/>
                                    </Col>
                                    <Col lg='8' md='12' xs='12' sm='12' >
                                        <TextCardTitle align="right" >
                                            {name}
                                        </TextCardTitle>
                                        <Row>
                                            <Col xs='auto' className='pt-2' >
                                                <i><FontAwesomeIcon icon={faBuilding} size='md' color='#ff4136' /></i> {organizationId.name}
                                            </Col>
                                        </Row>
                                        <Row className='pt-1'>
                                            <Col xs='12' sm='12' md='auto'   >
                                                <TextCardSubtitle color='#000000' align="right">
                                                    <i><FontAwesomeIcon icon={faDollarSign} size='md' color='#ff4136' /></i> {lang.serviceFees + ':'} {amount} {lang.pound}
                                                </TextCardSubtitle>
                                            </Col>
                                            <Col  >
                                                {status ?
                                                    <Button onClick={() => servicePage(service)} size='sm' fullWidth text={lang.servicesApplied} />
                                                    :
                                                    <Button onClick={() => servicePage(service)} type='secondary' size='sm' fullWidth text={lang.apply} />
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className='job-card-footer' >
                                    <p className='job-card-keywords-title font-weight-bold'>
                                        {lang.keywords + ':'}
                                    </p>
                                    {keywords && keywords.map((key, i) => (
                                        <span className='job-card-keywords-sub' >
                                            {'\u00A0' + key + (i < keywords.length - 1 ? ',\u00A0' : '')}
                                        </span>
                                    ))}
                                </Row>
                            </Col>
                        )
                    })}
                </Row>
            </Fade>


        </Container>
    )
}
export function ServiceDetailsCard(props) {

    const { service, lang } = props
    return (

        <Container  >
            <Row className='job-card-container' >
                <Col xs='12' className='job-card' >
                    <div className='job-details-card-label'>
                        {service.type}
                    </div>
                    <Row className='job-details-card-header' >
                        <Col>
                            <TextToolbar black margin>
                                {lang.orgName}
                            </TextToolbar>
                            <TextRegSub right>
                                {service.organizationId.name}
                            </TextRegSub>
                            <TextToolbar black margin>
                                {lang.aboutService}
                            </TextToolbar>
                            <TextRegSub right>
                                {service.description}
                            </TextRegSub>
                            <TextToolbar black margin>
                                {lang.branches}
                            </TextToolbar>
                            {service.branchId.map(value =>
                                <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', padding: 5, }}>
                                    <FontAwesomeIcon style={{ marginLeft: 7 }} size='sm' icon={faMapMarkerAlt} color='#ea6969' />

                                    <TextRegSub right>
                                        <a href={value.location} target="_blank">
                                            {value.name}
                                        </a>
                                    </TextRegSub>
                                </div>
                            )}

                            <TextToolbar black margin>
                                {lang.serviceProgreses}
                            </TextToolbar>
                            {service.process.map(value =>
                                <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', padding: 5, }}>
                                    <FontAwesomeIcon style={{ marginLeft: 7 }} size='2x' icon={faCaretLeft} color='#ea6969' />
                                    <TextRegSub right>
                                        {value}
                                    </TextRegSub>
                                </div>
                            )}
                            <TextToolbar black margin>
                                {lang.paperNedded}
                            </TextToolbar>
                            {service.documentsRequired.map(value =>
                                <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', padding: 5, }}>
                                    <FontAwesomeIcon style={{ marginLeft: 7 }} size='2x' icon={faCaretLeft} color='#ea6969' />
                                    <TextRegSub right>
                                        {value}
                                    </TextRegSub>
                                </div>
                            )}
                        </Col>
                    </Row>
                    <Row className='job-card-footer' >
                        <p className='job-card-keywords-title font-weight-bold'>
                            {lang.keywords + ':'}
                        </p>
                        {service.keywords && service.keywords.map((key, i) => (
                            <span className='job-card-keywords-sub' >
                                {'\u00A0' + key + (i < service.keywords.length - 1 ? ',\u00A0' : '')}
                            </span>
                        ))}
                    </Row>
                </Col>

            </Row>


        </Container>


    )
}

export function OrganizationsPageCard(props) {
    const [currentPage, setPage] = useState(1)
    const [start, setStart] = useState(1)
    const [end, setEnd] = useState(1)
    const { data, lang, orgPage } = props
    const Page = (value) => {
        setPage(value)
    }
    return (
        <Container  >
            <Row className='d-flex justify-content-center align-items-center job-card-header-panel mb-3'>
                <Col className='mt-3' xs='auto' >
                    <Pages
                        pageSize={10}
                        maxPages={3}
                        total={data && data.length}
                        setPage={Page}
                        currentPage={currentPage}
                        start={setStart}
                        end={setEnd}
                    />
                </Col>
            </Row>
            <Fade nav={currentPage} >
                <Row className='p-1' >
                    {data.length > 0 && data.slice(start, end).map((org, index) => {
                        const { avatar, name, address, jobs, services } = org
                        return (
                            <Col key={index} xs='12' className='org-profile-card-container'>
                                <Row className='p-4  justify-content-between align-items-center'>
                                    <Col xs='auto' className=' pb-3 '  >
                                        <Row className='justify-content-center align-items-center'>
                                            <Col xs='auto'>
                                                <img loading="lazy" alt={''} style={{ width: '120px', height: '120px', borderRadius: '25px' }} src={lang.baseUrl + '/org/media/imgs/' + avatar} />
                                            </Col>
                                            <Col xs='auto' >
                                                <Col>
                                                    <Row className='align-items-center'>
                                                        <i style={{ paddingLeft: 6 }}><FontAwesomeIcon icon={faDotCircle} color='#ea6969' /></i>
                                                        <TextCardTitle lineHeight='3'  >
                                                            {name}
                                                        </TextCardTitle>
                                                    </Row>
                                                </Col>
                                                <Col>
                                                    <Row className='align-items-center'>
                                                        <i style={{ paddingLeft: 6 }}><FontAwesomeIcon icon={faMapMarkerAlt} color='#ea6969' /></i>
                                                        <TextRegSub>
                                                            {address}
                                                        </TextRegSub>
                                                    </Row>
                                                </Col>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs='12' sm='12' md='auto' className='jusify-content-center '  >
                                        <TextRegSub >
                                            {lang.exist} <span style={{ color: '#ea6969', }} >{services}</span> {lang.service}{','} <span style={{ color: '#ea6969', }} >{jobs}</span> {lang.job} {lang.available}
                                        </TextRegSub>
                                        <Button onClick={() => orgPage(org)} margin fullWidth type='secondary' text={lang.orgProfile} size='sm' />
                                    </Col>
                                </Row>
                            </Col>

                        )
                    })}
                </Row>



            </Fade>


        </Container>
    )
}
export function OrgDetailsCard(props) {

    const { org, lang } = props
    return (
        <Container  >
            <Row className='org-profile-card-container' >
                <Col xs='12' className='job-card' >
                    <Row className='job-details-card-header' >
                        <Col>
                            <TextToolbar black margin>
                                {lang.aboutOrg}
                            </TextToolbar>
                            <TextRegSub right>
                                {org.brief}
                            </TextRegSub>
                            <TextToolbar black margin>
                                {lang.username}
                            </TextToolbar>
                            <TextRegSub right>
                                {org.username}
                            </TextRegSub>
                            <TextToolbar black margin>
                                {lang.contacts}
                            </TextToolbar>
                            <TextRegSub margin right >
                                <TextRegSub margin right >
                                    <Row>
                                        <Col xs='3'>{lang.email}</Col> <Col xs='1'>{':'}</Col > <Col xs='auto'>{org.email}</Col>
                                    </Row>
                                </TextRegSub>
                                <Row>
                                    <Col xs='3'>{lang.phone}</Col> <Col xs='1'>{':'}</Col > <Col xs='auto'>{org.phone}</Col>
                                </Row>
                            </TextRegSub>
                            <TextRegSub margin right >
                                <Row>
                                    <Col xs='3'>{lang.mobile}</Col> <Col xs='1'>{':'}</Col > <Col xs='auto'>{org.mobile}</Col>
                                </Row>
                            </TextRegSub>
                            <TextRegSub margin right >
                                <Row>
                                    <Col xs='3'>{lang.fax}</Col> <Col xs='1'>{':'}</Col > <Col xs='auto'>{org.fax}</Col>
                                </Row>
                            </TextRegSub>
                            <TextToolbar black margin>
                                {lang.orgDocuments}
                            </TextToolbar>
                            <DocsCard data={org.docs} />

                            <TextToolbar black margin>
                                {lang.siteServices}
                            </TextToolbar>
                            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', padding: 5, }}>
                                <FontAwesomeIcon style={{ marginLeft: 7 }} size='sm' icon={org.consultingServices ? faCheckCircle : faTimesCircle} color={org.consultingServices ? '#5fc0e9' : '#ea6969'} />
                                <TextRegSub right>
                                    {lang.consultingServices}
                                </TextRegSub>
                            </div>
                            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', padding: 5, }}>
                                <FontAwesomeIcon style={{ marginLeft: 7 }} size='sm' icon={org.trainingServices ? faCheckCircle : faTimesCircle} color={org.trainingServices ? '#5fc0e9' : '#ea6969'} />
                                <TextRegSub right>
                                    {lang.trainingServices}
                                </TextRegSub>
                            </div>
                            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', padding: 5, }}>
                                <FontAwesomeIcon style={{ marginLeft: 7 }} size='sm' icon={org.assessmentServices ? faCheckCircle : faTimesCircle} color={org.assessmentServices ? '#5fc0e9' : '#ea6969'} />
                                <TextRegSub right>
                                    {lang.assessmentServices}
                                </TextRegSub>
                            </div>
                            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', padding: 5, }}>
                                <FontAwesomeIcon style={{ marginLeft: 7 }} size='sm' icon={org.empowermentServices ? faCheckCircle : faTimesCircle} color={org.empowermentServices ? '#5fc0e9' : '#ea6969'} />
                                <TextRegSub right>
                                    {lang.empowermentServices}
                                </TextRegSub>
                            </div>
                            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', padding: 5, }}>
                                <FontAwesomeIcon style={{ marginLeft: 7 }} size='sm' icon={org.employmentServices ? faCheckCircle : faTimesCircle} color={org.employmentServices ? '#5fc0e9' : '#ea6969'} />
                                <TextRegSub right>
                                    {lang.employmentServices}
                                </TextRegSub>
                            </div>
                            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', padding: 5, }}>
                                <FontAwesomeIcon style={{ marginLeft: 7 }} size='sm' icon={org.financingServices ? faCheckCircle : faTimesCircle} color={org.financingServices ? '#5fc0e9' : '#ea6969'} />
                                <TextRegSub right>
                                    {lang.financingServices}
                                </TextRegSub>
                            </div>‫
                            <TextToolbar black margin>
                                {lang.socialMedia}
                            </TextToolbar>

                            <Row>
                                <a target="_blank" href={org.website}>
                                    <FontAwesomeIcon style={{ marginRight: 10, marginLeft: 10 }} size='2x' color={org.website ? '#5fc0e9' : '#797979'} icon={faGlobe} />
                                </a>
                                <a target="_blank" href={org.linkedin}>
                                    <FontAwesomeIcon style={{ marginRight: 10, marginLeft: 10 }} size='2x' color={org.linkedin ? '#5fc0e9' : '#797979'} icon={faLinkedin} />
                                </a>
                                <a target="_blank" href={org.facebook}>
                                    <FontAwesomeIcon style={{ marginRight: 10, marginLeft: 10 }} size='2x' color={org.facebook ? '#5fc0e9' : '#797979'} icon={faFacebookF} />
                                </a>
                                <a target="_blank" href={org.twitter}>
                                    <FontAwesomeIcon style={{ marginRight: 10, marginLeft: 10 }} size='2x' color={org.twitter ? '#5fc0e9' : '#797979'} icon={faTwitter} />
                                </a>

                                <a target="_blank" href={org.youtube}>
                                    <FontAwesomeIcon style={{ marginRight: 10, marginLeft: 10 }} size='2x' color={org.youtube ? '#5fc0e9' : '#797979'} icon={faYoutube} />
                                </a>
                                <a target="_blank" href={org.instagram}>
                                    <FontAwesomeIcon style={{ marginRight: 10, marginLeft: 10 }} size='2x' color={org.instagram ? '#5fc0e9' : '#797979'} icon={faInstagram} />
                                </a>
                            </Row>
                        </Col>
                    </Row>
                </Col>

            </Row>


        </Container>


    )
}
export function CoPageCard(props) {
    const [currentPage, setPage] = useState(1)
    const [start, setStart] = useState(1)
    const [end, setEnd] = useState(1)
    const { data, lang, comPage } = props
    const Page = (value) => {
        setPage(value)
    }
    return (
        <Container  >
            <Row className='d-flex justify-content-center align-items-center job-card-header-panel mb-3'>
                <Col className='mt-3' xs='auto' >
                    <Pages
                        pageSize={10}
                        maxPages={3}
                        total={data && data.length}
                        setPage={Page}
                        currentPage={currentPage}
                        start={setStart}
                        end={setEnd}
                    />
                </Col>
            </Row>
            <Fade nav={currentPage} >
                <Row className='p-1' >
                    {data.length > 0 && data.slice(start, end).map((co, index) => {
                        const { avatar, name, address, jobs } = co
                        return (
                            <Col key={index} xs='12' className='org-profile-card-container'>
                                <Row className='p-4  justify-content-between align-items-center'>
                                    <Col xs='auto' className=' pb-3 '  >
                                        <Row className='justify-content-center align-items-center'>
                                            <Col xs='auto'>
                                                <img loading="lazy" alt={''} style={{ width: '120px', height: '120px', borderRadius: '25px' }} src={lang.baseUrl + '/co/media/imgs/' + avatar} />
                                            </Col>
                                            <Col xs='auto' >
                                                <Col>
                                                    <Row className='align-items-center'>
                                                        <i style={{ paddingLeft: 6 }}><FontAwesomeIcon icon={faDotCircle} color='#ea6969' /></i>
                                                        <TextCardTitle lineHeight='3'  >
                                                            {name}
                                                        </TextCardTitle>
                                                    </Row>
                                                </Col>
                                                <Col>
                                                    <Row className='align-items-center'>
                                                        <i style={{ paddingLeft: 6 }}><FontAwesomeIcon icon={faMapMarkerAlt} color='#ea6969' /></i>
                                                        <TextRegSub>
                                                            {address}
                                                        </TextRegSub>
                                                    </Row>
                                                </Col>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs='12' sm='12' md='auto' className='jusify-content-center '  >
                                        <TextRegSub >
                                            {lang.exist} <span style={{ color: '#ea6969', }} >{jobs}</span> {lang.job} {lang.available}
                                        </TextRegSub>
                                        <Button onClick={() => comPage(co)} margin fullWidth type='secondary' text={lang.coProfile} size='sm' />
                                    </Col>
                                </Row>
                            </Col>

                        )
                    })}
                </Row>



            </Fade>


        </Container>
    )
}
export function ArticlesCard(props) {
    const [currentPage, setPage] = useState(1)
    const [start, setStart] = useState(1)
    const [end, setEnd] = useState(1)
    const { data, lang, articlePage } = props
    const Page = (value) => {
        setPage(value)
    }
    return (
        <Container  >
            <Row className='d-flex justify-content-center align-items-center job-card-header-panel mb-3'>
                <Col className='mt-3' xs='auto' >
                    <Pages
                        pageSize={10}
                        maxPages={3}
                        total={data && data.length}
                        setPage={Page}
                        currentPage={currentPage}
                        start={setStart}
                        end={setEnd}
                    />
                </Col>
            </Row>
            <Fade nav={currentPage} >
                <Row className='p-1' >
                    {data.length > 0 && data.slice(start, end).map((article, index) => {
                        const { sourceId, type, img, youtube, video, body } = article
                        return (
                            <Col key={index} xs='12' className='org-profile-card-container'>
                                <Row className='p-4  justify-content-between align-items-center'>
                                    <Col xs='auto' className=' pb-3 '  >
                                        <Row className='justify-content-center align-items-center'>

                                            <img loading="lazy" alt={''} style={{ width: '120px', height: '120px', borderRadius: '25px' }} src={lang.baseUrl + '/admin/media/imgs/' + img} />

                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        )
                    })}
                </Row>



            </Fade>


        </Container>
    )
}
export function CoDetailsCard(props) {

    const { co, lang } = props 
    return (

        <Container  >
            <Row className='org-profile-card-container' >
                <Col xs='12' className='job-card' >
                    <Row className='job-details-card-header' >
                        <Col>
                            <TextToolbar black margin>
                                {lang.aboutCo}
                            </TextToolbar>
                            <TextRegSub right>
                                {co.brief}
                            </TextRegSub>
                            <TextToolbar black margin>
                                {lang.username}
                            </TextToolbar>
                            <TextRegSub right>
                                {co.username}
                            </TextRegSub>
                            <TextToolbar black margin>
                                {lang.contacts}
                            </TextToolbar>
                            <TextRegSub margin right >
                                <Row>
                                    <Col xs='3'>{lang.phone}</Col> <Col xs='1'>{':'}</Col > <Col xs='auto'>{co.phone}</Col>
                                </Row>
                            </TextRegSub>
                            <TextRegSub margin right >
                                <Row>
                                    <Col xs='3'>{lang.mobile}</Col> <Col xs='1'>{':'}</Col > <Col xs='auto'>{co.mobile}</Col>
                                </Row>
                            </TextRegSub>
                            <TextRegSub margin right >
                                <Row>
                                    <Col xs='3'>{lang.email}</Col> <Col xs='1'>{':'}</Col > <Col xs='auto'>{co.email}</Col>
                                </Row>
                            </TextRegSub>
                            <TextToolbar black margin>
                                {lang.siteAvailability}
                            </TextToolbar>
                            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', padding: 5, }}>
                                <FontAwesomeIcon style={{ marginLeft: 7 }} size='sm' icon={faDoorOpen} color={'#5fc0e9'} />
                                <TextRegSub right>
                                    {lang.companyEntrance}{':'} {co.companyEntrance}
                                </TextRegSub>
                            </div>
                            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', padding: 5, }}>
                                <FontAwesomeIcon style={{ marginLeft: 7 }} size='sm' icon={co.wheelchairElevator ? faCheckCircle : faTimesCircle} color={co.wheelchairElevator ? '#5fc0e9' : '#ea6969'} />
                                <TextRegSub right>
                                    {lang.chairElevator}
                                </TextRegSub>
                            </div>
                            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', padding: 5, }}>
                                <FontAwesomeIcon style={{ marginLeft: 7 }} size='sm' icon={co.wheelchairOffice ? faCheckCircle : faTimesCircle} color={co.wheelchairOffice ? '#5fc0e9' : '#ea6969'} />
                                <TextRegSub right>
                                    {lang.chairOffice}
                                </TextRegSub>
                            </div>
                            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', padding: 5, }}>
                                <FontAwesomeIcon style={{ marginLeft: 7 }} size='sm' icon={co.wheelchairBathroom ? faCheckCircle : faTimesCircle} color={co.wheelchairBathroom ? '#5fc0e9' : '#ea6969'} />
                                <TextRegSub right>
                                    {lang.chairBathroom}
                                </TextRegSub>
                            </div>

                            <TextToolbar black margin>
                                {lang.transportation}
                            </TextToolbar>
                            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', padding: 5, }}>
                                <FontAwesomeIcon style={{ marginLeft: 7 }} size='sm' icon={faBus} color={'#5fc0e9'} />
                                <TextRegSub right>
                                    {co.transportation ? (lang.transportationType + ': ' + co.transportationType) : lang.noTransportation}
                                </TextRegSub>
                            </div>
                            <TextToolbar black margin>
                                {lang.socialMedia}
                            </TextToolbar>

                            <Row>
                                <a target="_blank" href={co.website}>
                                    <FontAwesomeIcon style={{ marginRight: 10, marginLeft: 10 }} size='2x' color={co.website ? '#5fc0e9' : '#797979'} icon={faGlobe} />
                                </a>
                                <a target="_blank" href={co.linkedin}>
                                    <FontAwesomeIcon style={{ marginRight: 10, marginLeft: 10 }} size='2x' color={co.linkedin ? '#5fc0e9' : '#797979'} icon={faLinkedin} />
                                </a>
                                <a target="_blank" href={co.facebook}>
                                    <FontAwesomeIcon style={{ marginRight: 10, marginLeft: 10 }} size='2x' color={co.facebook ? '#5fc0e9' : '#797979'} icon={faFacebookF} />
                                </a>
                                <a target="_blank" href={co.twitter}>
                                    <FontAwesomeIcon style={{ marginRight: 10, marginLeft: 10 }} size='2x' color={co.twitter ? '#5fc0e9' : '#797979'} icon={faTwitter} />
                                </a>

                                <a target="_blank" href={co.youtube}>
                                    <FontAwesomeIcon style={{ marginRight: 10, marginLeft: 10 }} size='2x' color={co.youtube ? '#5fc0e9' : '#797979'} icon={faYoutube} />
                                </a>
                                <a target="_blank" href={co.instagram}>
                                    <FontAwesomeIcon style={{ marginRight: 10, marginLeft: 10 }} size='2x' color={co.instagram ? '#5fc0e9' : '#797979'} icon={faInstagram} />
                                </a>
                            </Row>
                        </Col>
                    </Row>
                </Col>

            </Row>
        </Container>


    )
}
export function ComCard(props) {

    const { onClick, data, page, lang } = props

    return (
        <Container fluid>
            <SwitchTransition mode={'out-in'}>
                <CSSTransition
                    key={page}
                    addEndListener={(node, done) => {
                        node.addEventListener("transitionend", done, false);
                    }}
                    classNames="fade"
                >
                    <Row>
                        {data && data.map((card, index) => {
                            const { img, title, location, jobs } = card
                            return (
                                <Col key={index} sm='12' md='6' lg='6' xl='3' style={{ paddingRight: '7px', paddingLeft: '7px' }} >
                                    <div className='card-co' onClick={onClick} >
                                        <Col>
                                            <img style={{
                                                width: '100px',
                                                height: '100px',
                                                borderRadius: '15px',
                                                backgroundColor: '#0e132726',
                                                marginBottom: 10
                                            }} src={img} alt={''} loading="lazy"/>
                                        </Col>
                                        <Col>
                                            <TextCardTitle  >
                                                {TextCardTitle && title}
                                            </TextCardTitle>
                                        </Col>
                                        <Col >
                                            <TextCardSubtitle  >
                                                {location && location}
                                            </TextCardSubtitle>
                                        </Col>
                                        <Col>
                                            <Button fullWidth type='dark' text={lang.exist + ' ' + jobs + ' ' + lang.availableJobs} />
                                        </Col>

                                    </div>
                                </Col>
                            )
                        })}
                    </Row>
                </CSSTransition>
            </SwitchTransition>
        </Container>
    )
}

export function RegisterCard(props) {
    const { lang } = props

    return (
        <div className="card-register-container" >

            <div className="card-register-right">
                <div className="card-register-left-box">
                    <i style={{ fontSize: '30px' }}><FontAwesomeIcon icon={faUser} color='#5fc0e9' /></i>
                    <TextToolbar center black >
                        {lang.registerEmployeer}
                    </TextToolbar>
                    <TextRegSub>
                        {lang.regDesc}
                    </TextRegSub>
                    <div style={{ paddingTop: '3%' }}>
                        <Button fullWidth text={lang.regEmpBtn} />
                    </div>
                </div>
            </div>
            <div className="card-register-left">
                <div className="card-register-right-box ">
                    <i style={{ fontSize: '30px' }}><FontAwesomeIcon icon={faDesktop} color='#5fc0e9' /></i>
                    <TextToolbar center  >
                        {lang.registerCompany}
                    </TextToolbar>
                    <TextRegSub>
                        {lang.regDesc}
                    </TextRegSub>
                    <div style={{ paddingTop: '3%' }}>
                        <Button type='secondary' fullWidth text={lang.regCoBtn} />

                    </div>
                </div>
                <div className="card-register-tag">
                    <p>{lang.or}</p>
                </div>
            </div>

        </div>

    )
}
export function RegisterFooter(props) {
    const { data } = props
    return (
        <Container fluid>
            <Row  >
                <Col sm='12' md='12' lg='3' xl='3' className='register-footer'>
                    <TextRF>
                        <CountUp suffix='+' end={data[0].number} redraw={true}>
                            {({ countUpRef, start }) => (
                                <VisibilitySensor onChange={start} delayedCall>
                                    <span ref={countUpRef} />
                                </VisibilitySensor>
                            )}
                        </CountUp>
                    </TextRF>
                    <TextRFSub>
                        {data[0].name}
                    </TextRFSub>
                </Col  >
                <Col sm='12' md='12' lg='3' xl='3' className='register-footer-b'>
                    <TextRF>
                        <CountUp suffix='+' end={data[1].number} redraw={true}>
                            {({ countUpRef, start }) => (
                                <VisibilitySensor onChange={start} delayedCall>
                                    <span ref={countUpRef} />
                                </VisibilitySensor>
                            )}
                        </CountUp>


                    </TextRF>
                    <TextRFSub>
                        {data[1].name}

                    </TextRFSub>
                </Col >
                <Col sm='12' md='12' lg='3' xl='3' className='register-footer'>
                    <TextRF  >
                        <CountUp suffix='+' end={data[2].number} redraw={true}>
                            {({ countUpRef, start }) => (
                                <VisibilitySensor onChange={start} delayedCall>
                                    <span ref={countUpRef} />
                                </VisibilitySensor>
                            )}

                        </CountUp>
                    </TextRF>
                    <TextRFSub>
                        {data[2].name}

                    </TextRFSub>
                </Col >
                <Col sm='12' md='12' lg='3' xl='3' className='register-footer-b'>
                    <TextRF>
                        <CountUp suffix='+' end={data[3].number} redraw={true}>
                            {({ countUpRef, start }) => (
                                <VisibilitySensor onChange={start} delayedCall>
                                    <span ref={countUpRef} />
                                </VisibilitySensor>
                            )}
                        </CountUp>


                    </TextRF>
                    <TextRFSub>
                        {data[3].name}

                    </TextRFSub>
                </Col >
            </Row>
        </Container>

    )

}

export function RecomCard(props) {
    const { onClick, data, lang } = props
    return (
        <Container>
            <Row>
                {data.map(rec => {
                    const { name, jobTitle, img } = rec
                    return (
                        <Col xs='12' sm='12' md='12' lg='6' xl='6'>
                            <div style={{ margin: '5px' }}>
                                <Row className='recom-card d-flex align-items-center' onClick={onClick} >
                                    <Col xs='3' sm='3' md='2' lg='2' xl='2' >
                                        <img style={{
                                            width: '70px',
                                            height: '70px',
                                            borderRadius: '15px',
                                        }} src={img} alt={''} loading="lazy"/>
                                    </Col>
                                    <Col xs='9' sm='9' md='6' lg='6' xl='6' >
                                        <div className='serv-card-title' >
                                            {name}
                                        </div>
                                        <div className='d-flex align-items-center'>
                                            <i style={{ paddingLeft: 10 }}><FontAwesomeIcon icon={faDotCircle} color='#ea6969' /></i>

                                            <div className='serv-card-subtitle' >
                                                {jobTitle}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs='12' sm='12' md='4' lg='4' xl='4' >
                                        <div style={{ paddingTop: 10 }}>
                                            <Button size='sm' fullWidth type='dark' text={lang.profile} />

                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    )
                })}

            </Row>
        </Container>

    )
}

export function DocsCard(props) {
    const { data } = props
    return (
        <Container className='overflow-h mt-3 docs-card-container' >
            {data && data.map((doc, i) => {
                return (
                    <div className='docs-card'  key={i}>
                        {doc.name}
                        <div style={{ fontSize: '18px' }} >
                            <i style={{ marginRight: '10px' }} onClick={() => downloadFile({ url: '/admin/api/org/files/' + doc.url, name: doc.name })} > < FontAwesomeIcon icon={faDownload} color={'#5fc0e9'} className='docs-card-icon' /></i>
                        </div>
                    </div>
                )
            })}
        </Container >
    )
}

// export function HomeJobCard({ lang }) {
//     const job = {
//         img: jobImg, jobTitle: lang.homeJobTitle, workType: lang.homeJobType,
//         keywords: lang.homeJobKeywords, salary: 3500 + lang.pound,
//         location: lang.homeJobLocation, company: lang.homeJobCompany

//     }
//     const { img, jobTitle, workType, keywords, salary, company, location } = job

//     return (
//                 <Row className='job-card-container' style={{}} >
//                     <Col style={{ paddingRight: 20, paddingLeft: 20, paddingBottom: 10, borderRadius:'30px' }} xs='12' className='job-card' >
//                         <div className='job-card-label'>
//                             {workType}
//                         </div>
//                         <Row className='job-card-header p-3 d-flex align-items-center'  style={{backgroundColor:'#ffff'}} >
//                             <Col md='12' xs='12' sm='12' lg='auto' >
//                                 <img style={{
//                                     width: '120px',
//                                     height: '120px',
//                                     borderRadius: '15px',
//                                 }} src={img} alt={''} loading="lazy"/>
//                             </Col>
//                             <Col lg='8' md='12' xs='12' sm='12' >
//                                 <TextCardTitle align="right" >
//                                     {jobTitle}
//                                 </TextCardTitle>
//                                 <TextCardSubtitle color='#5fc0e9' align="right">
//                                     {company}
//                                 </TextCardSubtitle>

//                                 <TextCardSubtitle color='#000000' align="right">

//                                     <i ><FontAwesomeIcon icon={faMapMarkerAlt} size='md' color='#ff4136' /></i> {location}

//                                 </TextCardSubtitle>

//                                 <Row  >
//                                     <Col xs='12' sm='12' md='auto'  >
//                                         <TextCardSubtitle color='#000000' align="right">
//                                             <i><FontAwesomeIcon icon={faDollarSign} size='md' color='#ff4136' /></i> {lang.salary} {salary}
//                                         </TextCardSubtitle>
//                                     </Col>

//                                     <Col  >
//                                         <Button type='secondary' size='sm' fullWidth text={lang.apply} />
//                                     </Col>
//                                 </Row>
//                             </Col>
//                         </Row>


//                         <Row className='job-card-footer' >
//                             <p className='job-card-keywords-title font-weight-bold'>
//                                 {lang.keywords + ':'}
//                             </p>
//                             <span className='job-card-keywords-sub' >
//                                 {keywords}
//                             </span>
//                         </Row>
//                     </Col>
//                 </Row>
//     )
// }

