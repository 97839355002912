import './css/text.css'

export function TextHeader(props) {
    const { text, color, align } = props
    const c = color ? color : '#ffffff'
    const Align = align ? align : 'right'
    return (
        <div style={{ color: c, textAlign: Align }} className='text-header'>
            {text}
        </div>
    )
}
export function TextLinks(props) {
    const { text, align, active, disabled } = props
    const Align = align ? align : 'right'
    const d = disabled ? '-disabled' : ''
    const Active = active ? 'text-links-active' : 'text-links' + d

    return (
        <div style={{ textAlign: Align }} className={Active}>
            {text}
        </div>
    )
}
export function TextTitle(props) {
    const { children, align, black, bold } = props
    const a = align ? align : ''
    const c = black ? 'black' : ''
    return (
        <div style={{ textAlign: a, fontFamily: 'Cairo', fontWeight: bold ? 'bold' : 'normal', color: c }} className='text-title'>
            {children}
        </div>
    )
    //was fontFamily:"ArabicBold"
}
export function TextSubtitle(props) {
    const { children, black, right, color, bold, size } = props
    return (
        <div style={{ fontSize: size ? size : '', color: black ? 'black' : (color ? color : ''), textAlign: right ? 'right' : 'center', fontWeight: bold ? 'bold' : '' }} className='text-subtitle'>
            {children}
        </div>
    )
}
export function TextBody(props) {
    const { children } = props
    return (
        <div className='text-Body'>
            {children}
        </div>
    )
}
export function TextToolbar(props) {
    const { children, black, center, margin } = props
    const m = margin ? '20px' : ''

    const color = black ? 'black' : 'white'
    const Class = center ? 'text-toolbar-center' : 'text-toolbar'
    const align = center ? 'center' : 'right'
    return (
        <div style={{ color: color, textAlign: align, marginTop: m, marginBottom: m, }} className={Class}>
            {children}
        </div>
    )
}
export function TextRF(props) {
    const { children, size } = props
    return (
        <div style={{fontSize:size?size:''}} className='text-register-footer'>
            {children}
        </div>
    )
}
export function TextRFSub(props) {
    const { children,size } = props
    return (
        <div style={{fontSize:size?size:''}} className='text-register-footer-sub'>
            {children}
        </div>
    )
}
export function DashTitle(props) {
    const { children } = props
    return (
        <div className='text-dashboard-title'>
            {children}
        </div>
    )
}
export function DashSub(props) {
    const { children, sub } = props
    const color = sub ? '#5fc0e9' : '#797979'
    return (
        <div style={{ color: color }} className='text-dashboard-sub'>
            {children}
        </div>
    )
}
export function TextRegSub(props) {
    const { children, right, size, margin, color,link, onClick } = props
    const align = right ? 'right' : 'center'
    const m = margin ? '7px' : ''

    return (
        <div onClick={onClick} style={{ textAlign: align, fontSize: size, paddingTop: m, paddingBottom: m, color: color ? color : '',cursor:link ? 'pointer' : '' }} className='text-register-subtitle'>
            {children}
        </div>
    )
}
export function TextComment(props) {
    const { children } = props
    return (
        <div className='text-clients-comment'>
            {children}
        </div>
    )
}
export function TextBtn(props) {
    const { children, black } = props

    const Color = black ? 'black' : 'black'
    return (
        <div style={{ color: Color, }} className='text-btn'>
            {children}
        </div>
    )
}

export function TextCardTitle(props) {
    const { children, color, align, lineHeight } = props

    return (
        <div style={{ color: color, textAlign: align, lineHeight: lineHeight }} className='text-card-title'>
            {children}
        </div>
    )
}

export function TextCardSubtitle(props) {
    const { children, color, align, margin } = props
    const m = margin ? '3px' : ''
    return (
        <div style={{ color: color, textAlign: align, paddingTop: m, paddingBottom: m }} className='text-card-subtitle'>
            {children}
        </div>
    )
}
