import './css/adminCards.css'
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import { Col, Row } from "react-bootstrap";
import { TextRegSub, TextRF, TextRFSub } from './text';
import { Button } from './button';
import { getImg } from '../Api';
import { useEffect, useState } from 'react';

export function AdminStatisticsCard({ data }) {
    const { number, name } = data
    return (
        <div className='admin-statistics-card'>
            <TextRF size='28px' >
                <CountUp suffix='+' end={number} redraw={true}>
                    {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                            <span ref={countUpRef} />
                        </VisibilitySensor>
                    )}
                </CountUp>
            </TextRF>
            <TextRFSub size='24px'>
                {name}
            </TextRFSub>
        </div>
    )

}

export function UsersCtrCard({ lang, data, userStatus, show, pwd, co }) {
    const { name, avatar, activeStatus, username } = data
    const url = pwd ? '/pwd/media/imgs/' : (co ? '/co/media/imgs/' : '/org/media/imgs/')
    const img = lang.baseUrl + url + avatar
    const status = typeof (activeStatus) === 'undefined' ? 'pending' : activeStatus

    return (
        <div className={'admin-uesr-container'}>
            <div className='admin-user-status' style={{ backgroundColor: status === 'rejected' ? '#ea6969' : (status === 'terminated' ? '#000000' : (status === 'new' ? '#5fc0e9' : '#63d09c')) }} />

            <div className='admin-uesr-content'>

                <div >
                    <img style={{
                        width: '40px',
                        height: '40px',
                        borderRadius: '7px'
                    }} src={img} loading="lazy" alt=""/>
                </div>
                <div onClick={() => status !== 'new' && show(data)} className='admin-uesr-title'>
                    {name ? name : username}
                </div>

                <div >
                    {(status === 'pending') &&
                        <div>
                            {!pwd && <div style={{ paddingRight: 15, width: '100%', paddingBottom: 10 }}>
                                <Button onClick={() => userStatus({ id: data._id, status: 'rejected' })} size='sm' type="outlineds" text={lang.userReject} />
                            </div>}

                            <div style={{ paddingRight: 15, width: '100%', }}>
                                <Button onClick={() => userStatus({ id: data._id, status: 'active' })} size='sm' type="outlined" text={lang.userAccept} />
                            </div>
                        </div>
                    }
                    {(status === 'new') &&
                        <div>
                            <div style={{ paddingRight: 15, width: '100%', paddingBottom: 10 }}>
                                <Button size='sm' text={lang.userNew} />
                            </div>
                            <div style={{ paddingRight: 15, width: '100%', }}>
                                <Button onClick={() => userStatus({ id: data._id, status: 'terminated' })} size='sm' type="outlineds" text={lang.userTerminate} />
                            </div>
                        </div>
                    }
                    {/* {status === 'new' && <div style={{ paddingRight: 15, width: '100%' }}>
                        <Button size='sm' text={lang.userNew} />
                    </div>} */}
                    {status === 'active' && <div style={{ paddingRight: 15, width: '100%' }}>
                        <Button onClick={() => userStatus({ id: data._id, status: 'terminated', jobStatus: false })} size='sm' type="outlineds" text={lang.userTerminate} />
                    </div>}
                    {status === 'terminated' && <div style={{ paddingRight: 15, width: '100%' }}>
                        <Button onClick={() => userStatus({ id: data._id, status: 'active', jobStatus: true })} size='sm' type="label" text={lang.userActivate} />
                    </div>}
                    {status === 'rejected' && <div style={{ paddingRight: 15, width: '100%' }}>
                        <Button onClick={() => userStatus({ id: data._id, status: 'active' })} size='sm' type="dark" text={lang.userRejectCancel} />
                    </div>}
                </div>

            </div>
        </div>
    )
}
export function RolesCtrCard({ lang, data, edit, remove }) {
    const [imgSrc, setImgSrc] = useState('');
    const { name, img, username, roleId, _id } = data
    const initData = async () => {
        const userImg = await getImg({ url: '/admin/media/imgs/' + img, lang })
        setImgSrc(userImg)
    }
    useEffect(() => {
        initData()
    }, [])
    return (
        <div className={'admin-uesr-container'}>
            <div className='admin-user-status' style={{ backgroundColor: '#63d09c' }} />

            <div className='admin-uesr-content'>

                <div >
                    <img style={{
                        width: '40px',
                        height: '40px',
                        borderRadius: '7px'
                    }} src={imgSrc && URL.createObjectURL(imgSrc)} loading="lazy" alt=""/>
                </div>
                <div className='roles-uesr-title'>
                    {name ? name : username} <span style={{ color: '#5fc0e9' }}>{roleId.name}</span>

                </div>
                <Row>
                    <Col xs='auto'>
                        <Button onClick={() => edit(data)} size='sm' text={lang.edit} />
                    </Col>
                    {data.username !== 'Admin' && 
                        <Col xs='auto'>
                            <Button onClick={() => remove(_id)} size='sm' type="secondary" text={lang.delete} />
                        </Col>
                    }
                </Row>

            </div>
        </div>
    )
}