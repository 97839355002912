import './css/button.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

const Size = (size) => {
    switch (size) {
        case 'sm': return { height: "30px", lineHeight: '30px', borderRadius: '11px', fontSize: '14px', }

        case 'lg': return { height: "48px", borderRadius: '15px', lineHeight: '48px' };
        case 'xl': return { height: "100px", borderRadius: '15px', lineHeight: '90px', fontSize: '32px' };
        default: return { height: "48px", borderRadius: '15px', lineHeight: '48px', }
    }
}
const Type = (type) => {
    switch (type) {
        case 'secondary': return { style: { background: '#ea6969' }, name: 'secondary' }
        case 'dark': return { style: { background: '#0e1327' }, name: 'dark' };
        case 'label': return { style: { background: '#63d09c' }, name: 'main' };
        case 'link': return { style: { background: '#63d09c' }, name: 'main' };
        case 'outlined': return { style: {}, name: 'outlined' };
        case 'outlineds': return { style: {}, name: 'outlineds' };

        default: return { style: { background: '#23c0e9' }, name: 'main' }
    }
}

export function Button(props) {
    const { text, type, onClick, size, icon, fullWidth, margin, disabled, title } = props
    const width = fullWidth ? '100%' : '194px'
    const style = { ...Size(size), ...Type(type).style, width: width, }
    const fontStyle = { ...Size(size) }
    const d = disabled ? '-disabled' : ''
    const Class = "cbtn-" + Type(type).name + d
    const m = margin ? '20px' : ''
    
    return (
        <button style={{ ...style, marginTop: m, marginBottom: m, }} onClick={!disabled && onClick}>
            {title && <div className='input-title'>
                {title}
            </div>}
            <div style={fontStyle} className={Class}  >
                {icon && <FontAwesomeIcon icon={icon} />} {text}
            </div>
        </button>
    )
}

export function AddBtn({ onClick }) {

    return (
        <div onClick={onClick} className='icon-btn'>
            <FontAwesomeIcon icon={faPlusCircle} />
        </div>
    )
}