import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { faStar as Fa} from '@fortawesome/free-regular-svg-icons'


export function Rates(props) {
    const { rate } = props
    const st = []
    for (let i = 1; i <= 5; i++) {
        st.push(

            <i style={{padding:5}}>
                            <FontAwesomeIcon color='#f0a836' icon={i <= rate ? faStar : Fa} style={{ fontSize: '24px', marginBottom: '7%' }} />
            </i>
        )
    }
    return (
        <div style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            {st}
        </div>

    )
}