import { EmploymentCard } from '../Components'
import {  Modal, Loading, Confirm, CSelect, CheckBox } from '../Components'
import { NPDWithIconInput } from '../Components'
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from 'react'
import { AdminCoTerminatedJobsApi, AdminCoActiveJobsApi, AdminActivateTerminateCoJobApi } from '../Api'
import { Col, Row, Card } from 'react-bootstrap';
import Job from '../Assets/imgs/jobs.webp' //neww

export function CurrentJobs({ lang }) {
    const [modal, setModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [activeJobs, setActiveJobs] = useState([])
    const [job, setJob] = useState()
    const [search, setSearch] = useState('')

    const initData = async () => {
        setActiveJobs(await AdminCoActiveJobsApi())
        setLoading(false)
    }
    useEffect(() => {
        initData()
    }, [ !modal])

    const show = (data) => {
        setJob(data)
        setModal(true)
    }
    const Show = () => {
        return <ShowJob lang={lang} data={job && job} />
    }
    const terminate = async (job) => {
        Confirm({ lang, confirmText: lang.terminate, msg: lang.jobTerminateMsg, remove: () => AdminActivateTerminateCoJobApi({ id: job, lang, initData, terminate:true }) })

    }
    // update the styles in the form
    const customStyles = {      
        input: {
            height: '45px',
            fontSize: '16px',
            borderBottomRightRadius: '0px',
            borderTopRightRadius: '0px',
            borderBottomLeftRadius: '15px',
            borderTopLeftRadius: '15px'
        }
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'row', }}>

            <div style={{ paddingRight: '20px', width: '100%', maxHeight: 900 }} className='overflow-h'>
                <Modal title={lang.showJobs}
                    onHide={() => setModal(false)}
                    show={modal}
                    Body={Show} />
                {loading &&
                    <div className='centerd-div'>
                        <Loading />
                    </div>
                }
                {!loading && activeJobs.length < 1 && (
                    <div className='centerd-div'>
                        {lang.noData}
                    </div>
                )}
                {activeJobs.length > 0 &&
                    <>
                        <Row >
                            <Col>
                                <NPDWithIconInput type="text" controlId="search" inputStyle={customStyles.input} name="search" icon={faSearch}
                                    onChange={(e)=> setSearch(e.target.value)} value={search} 
                                    placeholder={lang.search} />
                            </Col>
                            <br />
                        </Row>
                        <Row className='overflow-h' style={{ maxHeight: 520 }}>
                            {activeJobs.filter(entry => Object.values(entry).some(val => typeof val === "string" && val.includes(search))).map((job) => {
                                return (
                                    <Col xs='12' key={job._id} className="pb-3">
                                        <EmploymentCard company lang={lang} data={job} show={show} terminate={terminate} />
                                    </Col>
                                )
                            })}
                        </Row>
                    </>
                }
            </div>
        </div>
    )
}

export function TerminatedJobs({ lang }) {
    const [modal, setModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [terminatedJobs, setTerminatedJobs] = useState([])
    const [job, setJob] = useState()
    const [search, setSearch] = useState('')

    const initData = async () => {
        setTerminatedJobs(await AdminCoTerminatedJobsApi())
        setLoading(false)
    }
    useEffect(() => {
        initData()
    }, [!modal])
    const show = (data) => {
        setJob(data)
        setModal(true)
    }
    const Show = () => {
        return <ShowJob lang={lang} data={job && job} />
    }

    const activate = async (job) => {
        Confirm({ lang, active: true, confirmText: lang.activate, msg: lang.jobActivateMsg, remove: () => AdminActivateTerminateCoJobApi({ id: job, lang, alert, initData }) })
    }
    // update the styles in the form
    const customStyles = {      
        input: {
            height: '45px',
            fontSize: '16px',
            borderBottomRightRadius: '0px',
            borderTopRightRadius: '0px',
            borderBottomLeftRadius: '15px',
            borderTopLeftRadius: '15px'
        }
    }
    
    return (
        <div style={{ display: 'flex', flexDirection: 'row', }}>
            <div style={{ paddingRight: '20px', width: '100%', maxHeight: 650 }} className='overflow-h'>
                <Modal title={lang.showJobs}
                    onHide={() => setModal(false)}
                    show={modal}
                    Body={Show} />
                {loading &&
                    <div className='centerd-div'>
                        <Loading />
                    </div>
                }
                {!loading && terminatedJobs.length < 1 && (
                    <div className='centerd-div'>
                        {lang.noData}
                    </div>
                )}
                {terminatedJobs.length > 0 &&
                    <>
                        <Row >
                            <Col>
                                <NPDWithIconInput type="text" controlId="search" inputStyle={customStyles.input} name="search" icon={faSearch}
                                    onChange={(e)=> setSearch(e.target.value)} value={search} 
                                    placeholder={lang.search} />
                            </Col>
                            <br />
                        </Row>
                        <Row className='overflow-h' style={{ maxHeight: 520 }}>
                            {terminatedJobs.filter(entry => Object.values(entry).some(val => typeof val === "string" && val.includes(search))).map((job) => {
                                return (
                                    <Col xs='12' key={job._id} className="pb-3">
                                        <EmploymentCard terminated lang={lang} data={job && job} show={show} changeStatus={activate} />
                                    </Col>
                                )
                            })}
                        </Row>
                    </>
                }
            </div>
        </div>
    )
}

function ShowJob({ lang, data }) {
    const province = data.country && data.country.state.find(p => p._id === data.province)
    const trSpeciality = data.trainingSector && data.trainingSector.type.find(e => e._id === data.trainigSpeciality)
    const eduType = data.educationLevel && data.educationLevel.type.find(e => e._id === data.educationCertType)
    const eduCert = eduType && eduType.cert.find(e => e._id === data.educationCertificate)
    const ImgSrc = data.ref === 'organization' && data.img && data.img.length > 0 ? `${lang.baseUrl}/org/media/imgs/${data.img && data.img}` : data.ref === 'company' && data.img && data.img.length > 0 ?`${lang.baseUrl}/co/media/imgs/${data.img && data.img}`: Job
    const name = data.companyId.name

    return (
        <Row style={{ display: 'flex', flexDirection: 'row', }}>
            <Col lg="4">
                <Card.Img className="w-100" src={ImgSrc} alt={'صورة خلفية للوظيفة'} loading="lazy" style={{height: "194px"}}/>
                <Card.Header className='text-center' style={{color: "rgb(95, 192, 233)"}}>{name}</Card.Header>
            </Col>
            <Col lg="8" className='pt-4 px-5' >
                <Row>
                    <Card.Title>
                        {lang.basicInfo}
                    </Card.Title>    
                </Row>
                <Row>
                    <Col lg="6" md="6" sm="6" xs="6" className='pt-3'>
                        <Card.Subtitle className='text-right'>{lang.jobName}</Card.Subtitle>
                        <Card.Body className='text-right'>{data.jobTitle}</Card.Body> 
                    </Col>
                    <Col lg="6" md="6" sm="6" xs="6" className='pt-3'>
                        <Card.Subtitle className='text-right'>{lang.jobType}</Card.Subtitle>
                        <Card.Body className='text-right'>{data.workType}</Card.Body> 
                    </Col>
                </Row>
                <Row>
                    <Col lg="12" md="12" sm="12" xs="12" className='pt-3'>
                        <Card.Subtitle className='text-right'>{lang.aboutJob}</Card.Subtitle>
                        <Card.Body className='text-right'>{data.about}</Card.Body>
                    </Col>
                </Row>
                <Row>
                    <hr style={{borderTop: "1px solid rgb(95, 192, 233)"}}/>
                </Row>
                <Row>
                    <Col lg="12" md="12" sm="12" xs="12">
                        <Card.Body className='text-right'>
                            <CSelect multi disabled value={data.disabilityType && data.disabilityType.map(e => { return { value: e.disabilityId._id, label: e.disabilityId.name } })} title={lang.disType} />
                        </Card.Body>
                    </Col>
                </Row>
                <Row>
                    <Card.Title>
                        {lang.jobTargeting}
                    </Card.Title>    
                </Row>
                <Row >
                    <Col lg="6" md="6" sm="6" xs="6" className='pt-3'>
                        <Card.Subtitle className='text-right'>{lang.jobCountry}</Card.Subtitle>
                        <Card.Body className='text-right'>{data.country && data.country.name}</Card.Body> 
                    </Col>
                    <Col lg="6" md="6" sm="6" xs="6" className='pt-3'>
                        <Card.Subtitle className='text-right'>{lang.province}</Card.Subtitle>
                        <Card.Body className='text-right'>{province && province.name}</Card.Body> 
                    </Col>
                </Row>
                <Row >
                    <Col lg="6" md="6" sm="6" xs="6" className='pt-3'>
                        <Card.Subtitle className='text-right'>{lang.jobField}</Card.Subtitle>
                        <Card.Body className='text-right'>{data.workField && data.workField.name}</Card.Body> 
                    </Col>
                    <Col lg="6" md="6" sm="6" xs="6" className='pt-3'>
                        <Card.Subtitle className='text-right'>{lang.jobf}</Card.Subtitle>
                        <Card.Body className='text-right'>{data.workFieldType && data.workFieldType.name}</Card.Body> 
                    </Col>
                </Row>
                <Row >
                    <Col lg="6" md="6" sm="6" xs="6" className='pt-3'>
                        <Card.Subtitle className='text-right'>{lang.pwdTrainingSector}</Card.Subtitle>
                        <Card.Body className='text-right'>{data.trainingSector && data.trainingSector.name}</Card.Body> 
                    </Col>
                    <Col lg="6" md="6" sm="6" xs="6" className='pt-3'>
                        <Card.Subtitle className='text-right'>{lang.pwdTrainingSpeciality}</Card.Subtitle>
                        <Card.Body className='text-right'>{trSpeciality && trSpeciality.name}</Card.Body> 
                    </Col>
                </Row>
                <Row>
                    <Col lg="12" md="12" sm="12" xs="12" className='pt-3'>
                        <Card.Subtitle className='text-right'>{lang.pwdEdu}</Card.Subtitle>
                        <Card.Body className='text-right'>{data.educationLevel && data.educationLevel.name}</Card.Body>
                    </Col>
                </Row>
                <Row >
                    <Col lg="6" md="6" sm="6" xs="6" className='pt-3'>
                        <Card.Subtitle className='text-right'>{lang.pwdTrainingCertType}</Card.Subtitle>
                        <Card.Body className='text-right'>{eduType && eduType.name}</Card.Body> 
                    </Col>
                    <Col lg="6" md="6" sm="6" xs="6" className='pt-3'>
                        <Card.Subtitle className='text-right'>{lang.pwdDegree}</Card.Subtitle>
                        <Card.Body className='text-right'>{eduCert && eduCert.name}</Card.Body> 
                    </Col>
                </Row>
                <Row>
                    <hr style={{borderTop: "1px solid rgb(95, 192, 233)"}}/>
                </Row>
                <Row>
                    <Card.Title>
                        {lang.moreInfos}
                    </Card.Title>    
                </Row>
                <Row>
                    <Col lg="6" md="6" sm="6" xs="6" className='pt-3'>
                        <Card.Subtitle className='text-right'>{lang.minExp}</Card.Subtitle>
                        <Card.Body className='text-right'>{data.minExperience}</Card.Body> 
                    </Col>
                    <Col lg="6" md="6" sm="6" xs="6" className='pt-3'>
                        <Card.Subtitle className='text-right'>{lang.maxExp}</Card.Subtitle>
                        <Card.Body className='text-right'>{data.maxExperience}</Card.Body> 
                    </Col>
                </Row>
                <Row>
                    <Col lg="6" md="6" sm="6" xs="6" className='pt-3'>
                        <Card.Subtitle className='text-right'>{lang.minSalary}</Card.Subtitle>
                        <Card.Body className='text-right'>{data.minSalary}</Card.Body> 
                    </Col>
                    <Col lg="6" md="6" sm="6" xs="6" className='pt-3'>
                        <Card.Subtitle className='text-right'>{lang.maxSalary}</Card.Subtitle>
                        <Card.Body className='text-right'>{data.maxSalary}</Card.Body> 
                    </Col>
                </Row>
                <Row>
                    <Col lg="12" md="12" sm="12" xs="12">
                        <Card.Body className='text-right'>
                            <CheckBox disabled active={data.makeCvMandatory} margin title={lang.makeCvMandatory} /> 
                        </Card.Body>
                    </Col>
                </Row>
                <Row>
                    <hr style={{borderTop: "1px solid rgb(95, 192, 233)"}}/>
                </Row>
                <Row>
                    <Card.Title>
                        {lang.reqSkills}
                    </Card.Title>    
                </Row>
                <Row>
                    <Col lg="12" md="12" sm="12" xs="12">
                        <Card.Body className='text-right'>
                            <CSelect disabled value={data.skills && data.skills.map(d => { return { label: d.skillId.name } })} multi margin />
                        </Card.Body>
                    </Col>
                </Row>
                <Row>
                    <Card.Title>
                        {lang.avType}
                    </Card.Title>    
                </Row>
                <Row>
                    <Col lg="12" md="12" sm="12" xs="12">
                        <Card.Body className='text-right'>
                            <CSelect disabled value={data.disabilityEnabled && data.disabilityEnabled.map(d => { return { label: d } })} multi margin />
                        </Card.Body>
                    </Col>
                </Row>
                <Row>
                    <Card.Title>
                        {lang.responsibilities}
                    </Card.Title>    
                </Row>
                <Row>
                    <Col lg="12" md="12" sm="12" xs="12">
                        <Card.Body className='text-right'>
                            <CSelect disabled value={data.responsibilities && data.responsibilities.map(d => { return { label: d } })} multi margin />
                        </Card.Body>
                    </Col>
                </Row>
                <Row>
                    <Card.Title>
                        {lang.appSteps}
                    </Card.Title>    
                </Row>
                <Row>
                    <Col lg="12" md="12" sm="12" xs="12">
                        <Card.Body className='text-right'>
                            <CSelect disabled value={data.procedures && data.procedures.map(d => { return { label: d } })} multi margin />
                        </Card.Body>
                    </Col>
                </Row>
                <Row>
                    <Card.Title>
                        {lang.keywords}
                    </Card.Title>    
                </Row>
                <Row>
                    <Col lg="12" md="12" sm="12" xs="12">
                        <Card.Body className='text-right'>
                            <CSelect disabled value={data.keywords && data.keywords.map(d => { return { label: d } })} multi margin placeholder={lang.keywords} />
                        </Card.Body>
                    </Col>
                </Row>
            </Col>
        </Row> 
    )
}