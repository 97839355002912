import {
    Route, Routes,
    useNavigate
} from 'react-router-dom';
import { lazy, Suspense } from 'react'
import { ErrorPage, SplachPage } from './Components'
import useAuth, { AuthProvider } from "./Api/auth";

// home
const Admin = lazy(() => import('./Admin'));
const Login = lazy(() => import('./Login'));

function Router(props) {
    const { user, statistics, contactUs } = useAuth()
    const navigate = useNavigate()

    return (
        <Routes>
            <Route path="/" element={<Admin navigate={navigate} contactUs={contactUs} user={user} {...props} />} />
            <Route path="/Login" element={<Login navigate={navigate} contactUs={contactUs} {...props} />} />
            <Route path="/network-error" element={<ErrorPage navigate={navigate} {...props} />} />
            <Route path="*" element={<Admin navigate={navigate} contactUs={contactUs} statistics={statistics} user={user} {...props} />} />
        </Routes>
    );
}


export default function Routers(props) {

    return (
        <Suspense fallback={<SplachPage />}>
            <AuthProvider {...props}>
                <Router {...props} />
            </AuthProvider>
        </Suspense>
    );
}