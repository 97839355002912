const EgProvinces = [
    { value: 'ALX', label: 'الإسكندرية', },
    { value: 'IS', label: 'الإسماعيلية', },
    { value: 'ASN', label: 'أسوان', },
    { value: 'AST', label: 'أسيوط', },
    { value: 'LX', label: 'الأقصر', },
    { value: 'BA', label: 'البحر الأحمر ', },
    { value: 'BH', label: 'البحيرة', },
    { value: 'BNS', label: 'بني سويف', },
    { value: 'PTS', label: 'بورسعيد', },
    { value: 'JS', label: 'جنوب سيناء ', },
    { value: 'GZ', label: 'الجيزة', },
    { value: 'DK', label: 'الدقهلية', },
    { value: 'DT', label: 'دمياط', },
    { value: 'SHG', label: 'سوهاج', },
    { value: 'SUZ', label: 'السويس', },
    { value: 'SHR ', label: 'الشرقية ', },
    { value: 'SIN', label: 'شمال سيناء ', },
    { value: 'HUR', label: 'الغربية', },
    { value: 'FYM', label: 'الفيوم', },
    { value: 'CA', label: 'القاهرة', },
    { value: 'KB ', label: 'القليوبية ', },
    { value: 'KN', label: 'قنا', },
    { value: 'KFS', label: 'كفر الشيخ ', },
    { value: 'MT', label: 'مطروح', },
    { value: 'MNF', label: 'المنوفية', },
    { value: 'MN ', label: 'المنيا ', },
    { value: 'WAD', label: 'الوادي الجديد ', },
    { value: 'HU', label: 'حلوان', },
    { value: 'SU', label: 'السادس من اكتوبر', },
]
export const countries = [
    { label: 'مصر', value: 'EG', provinces: EgProvinces }
]
export const organizationtypes = [
    { value: 'حكومي', label: 'حكومي', },
    { value: 'خاص', label: 'خاص', },
    { value: 'شركة ربحية', label: 'شركة ربحية', },
    { value: 'مؤسسة غير هادفة للربح', label: 'مؤسسة غير هادفة للربح', },
    { value: 'شركات صغيرة', label: 'شركات صغيرة', },
    { value: 'شركات كبيرة', label: 'شركات كبيرة', },
]
export const fields = [
    { value: 'قطاع سياحة', label: 'قطاع سياحة', },
    { value: 'فنقدي', label: 'فندقي', },
]
export const workTypes = [
    { value: 'دوام كامل', label: 'دوام كامل', },
    { value: 'دوام جزئي', label: 'دوام جزئي', },
]
const engTypes = [
    { value: 'مخطط مدن', label: 'مخطط مدن' },
    { value: 'مهندس شبكات', label: 'مهندس شبكات' },
    { value: 'مهندس برمجيات', label: 'مهندس برمجيات' },
    { value: 'مهندس بيئة', label: 'مهندس بيئة' },
]
const itTypes = [

    { value: 'محلل بيانات', label: 'محلل بيانات' },
    { value: 'مدير نظم معلومات', label: 'مدير نظم معلومات' },
    { value: 'مبرمج', label: 'مبرمج' },
    { value: 'كاتب محتوى ويب', label: 'كاتب محتوى ويب' },
    { value: 'مهندس برمجيات', label: 'مهندس برمجيات' },
    { value: 'محلل نظم', label: 'محلل نظم' },
    { value: 'محلل امن معلومات', label: 'محلل امن معلومات' },
    { value: 'الدعم الفنى', label: 'الدعم الفنى' },
    { value: 'مبرمج العاب اليكترونية', label: 'مبرمج العاب اليكترونية' },
    { value: 'مهندس شبكات', label: 'مهندس شبكات' },
]
const mediaTypes = [
    { value: 'ممثل', label: 'ممثل' },
    { value: 'صحفي', label: 'صحفي' },
    { value: 'مذيع', label: 'مذيع' },
    { value: 'مراسل صحفى', label: 'مراسل صحفى' },
    { value: 'محرر', label: 'محرر' },
    { value: 'امين مكتبة', label: 'امين مكتبة' },
]
const eduTypes = [
    { value: 'معلم', label: 'معلم' },
    { value: 'مرشد مدرسة', label: 'مرشد مدرسة' },
    { value: 'امين مكتبة', label: 'امين مكتبة' },
]
const mngTypes = [
    { value: 'سكرتارية', label: 'سكرتارية' },
    { value: 'تسويق اليكترونى', label: 'تسويق اليكترونى' },
    { value: 'محاسب', label: 'محاسب' },
    { value: 'مراجع حسابات', label: 'مراجع حسابات' },
    { value: 'ادارة الموارد البشرية', label: 'ادارة الموارد البشرية' },
    { value: 'المخازن', label: 'المخازن' },
    { value: 'المشتريات', label: 'المشتريات' },
]
const techTypes = [
    { value: 'فنى كهرباء ', label: 'فنى كهرباء ' },
    { value: 'فنى طاقة شمسية', label: 'فنى طاقة شمسية' },
    { value: 'فنى سباكة', label: 'فنى سباكة' },
    { value: 'فنى ميكانيكا', label: 'فنى ميكانيكا' },
    { value: 'فنى اجهزه اليكترونية', label: 'فنى اجهزه اليكترونية' },
    { value: 'فنى تبريد', label: 'فنى تبريد' },
    { value: 'فنى حدادة', label: 'فنى حدادة' },
    { value: 'فنى مصاعد', label: 'فنى مصاعد' },
    { value: 'فنى تكييف وتبريد', label: 'فنى تكييف وتبريد' },
    { value: 'فنى دهان', label: 'فنى دهان' },
    { value: 'فنى سمكرة', label: 'فنى سمكرة' },
]
const artTypes = [
    { value: 'ممثل', label: 'ممثل' },
    { value: 'كاتب', label: 'كاتب' },
    { value: 'مؤلف موسيقى', label: 'مؤلف موسيقى' },
    { value: 'مصمم صوت', label: 'مصمم صوت' },
    { value: 'مساعد مخرج', label: 'مساعد مخرج' },
    { value: 'رسام كاريكاتير', label: 'رسام كاريكاتير' },
    { value: 'مصور', label: 'مصور' },
]
const freelanceTypes = [
    { value: 'كاتب محتوى', label: 'كاتب محتوى' },
    { value: 'مدقق لغوى', label: 'مدقق لغوى' },
    { value: 'مبرمج', label: 'مبرمج' },
    { value: 'مسوق اليكترونى', label: 'مسوق اليكترونى' },
]
const tourTypes = [
    { value: 'ارشاد سياحي', label: 'ارشاد سياحي' },
    { value: 'استقبال', label: 'استقبال' },
    { value: 'مشرف', label: 'مشرف' },
    { value: 'مشرف خدمات غرف', label: 'مشرف خدمات غرف' },
    { value: 'منقذ سباحة', label: 'منقذ سباحة' },
    { value: 'موطف حجوزات', label: 'موطف حجوزات' },
    { value: 'موظف علاقات عامة', label: 'موظف علاقات عامة' },
    { value: 'موظف خدمة عملاء', label: 'موظف خدمة عملاء' },
    { value: 'مطبخ', label: 'مطبخ' },
]
const transTypes = [
    { value: 'مترجم', label: 'مترجم' },
    { value: 'كاتب محتوى', label: 'كاتب محتوى' },
    { value: 'مدقق لغوى', label: 'مدقق لغوى' },
    { value: 'امين مكتبة', label: 'امين مكتبة' },
    { value: 'كاتب تقنى', label: 'كاتب تقنى' },
    { value: 'محرر', label: 'محرر' },
]
const medTypes = [
    { value: 'طبيب', label: 'طبيب' },
    { value: 'مساعد طبيب', label: 'مساعد طبيب' },
    { value: 'تمريض', label: 'تمريض' },
    { value: 'ممارس عام', label: 'ممارس عام' },
    { value: 'علاج طبيعى', label: 'علاج طبيعى' },

]
const lowTypes = [
    { value: 'محامى', label: 'محامى' },
    { value: 'اخصائى قانونى', label: 'اخصائى قانونى' },
    { value: 'باحث قانونى', label: 'باحث قانونى' },
    { value: 'سكرتير قانوني', label: 'سكرتير قانوني' },
]
export const workFields = [
    { value: 'وظائف الهندسية', label: 'وظائف الهندسية', types: engTypes },
    { value: 'وظائف تكنولوجيا المعلومات', label: 'وظائف تكنولوجيا المعلومات', types: itTypes },
    { value: 'وظائف الصحافة والإعلام والتلفزيون', label: 'وظائف الصحافة والإعلام والتلفزيون', types: mediaTypes },
    { value: 'وظائف قطاع التعليم', label: 'وظائف قطاع التعليم', types: eduTypes },
    { value: 'وظائف الإدارة والأعمال والمحاسبة', label: 'وظائف الإدارة والأعمال والمحاسبة', types: mngTypes },
    { value: 'وظائف المهنية والفنية والتقنية', label: 'وظائف المهنية والفنية والتقنية', types: techTypes },
    { value: 'وظائف الفنون', label: 'وظائف الفنون', types: artTypes },
    { value: 'وظائف العمل الحر والعمل عن بعد', label: 'وظائف العمل الحر والعمل عن بعد', freelanceTypes },
    { value: 'وظائف السياحة والفندقة', label: 'وظائف السياحة والفندقة', types: tourTypes },
    { value: 'وظائف الترجمة واللغات والنشر', label: 'وظائف الترجمة واللغات والنشر', types: transTypes },
    { value: 'وظائف الطبية', label: 'وظائف الطبية', types: medTypes },
    { value: 'وظائف الحقوق والقانون', label: 'وظائف الحقوق والقانون', types: lowTypes },
]

const visual = [
    { value: 'ضعف بصر', label: 'ضعف بصر' },
    { value: 'كف بصر', label: 'كف بصر' },
]
const hearing = [
    { value: 'توصيلي', label: 'توصيلي' },
    { value: 'حص عصبي', label: 'حص عصبي' },
    { value: 'مختلط', label: 'مختلط' },
]

const mobility = [
    { value: 'شلل اطفال', label: 'شلل اطفال' },
    { value: 'شلل رباعي', label: 'شلل رباعي' },
    { value: 'شلل نصفي', label: 'شلل نصفي' },
    { value: 'اصابات الحبل الشوكي', label: 'اصابات الحبل الشوكي' },
    { value: 'ضمور', label: 'ضمور' },
    { value: 'الجلطة الدماغية', label: 'الجلطة الدماغية' },
    { value: 'الخلل العصبي الطرفي', label: 'الخلل العصبي الطرفي' },
    { value: 'تشوة العمود الفقري', label: 'تشوة العمود الفقري' },
    { value: 'القزامة', label: 'القزامة' },
    { value: 'البتر في طرف او اكتر', label: 'البتر في طرف او اكتر' },
    { value: 'الشلل الدماغي', label: 'الشلل الدماغي' },
]

const verbal = [

]
const intellectual = [
    { value: 'بسيط', label: 'بسيط' },
    { value: 'متوسط', label: 'متوسط' },
    { value: 'شديد', label: 'شديد' },
    { value: 'بالغ الشدة', label: 'بالغ الشدة' },
]
const physiologicalimpaired = [
    { value: 'اكتئاب', label: 'اكتئاب' },
    { value: 'الاضراب الوجداني ثنائي القطب', label: 'الاضراب الوجداني ثنائي القطب' },
    { value: 'انفصام الشخصية', label: 'انفصام الشخصية' },
    { value: 'الخرف', label: 'الخرف' },
]
export const disTypes = [
    { value: 'إعاقة بصرية', label: 'إعاقة بصرية', type: visual },
    { value: 'إعاقة سمعية', label: 'إعاقة سمعية', type: hearing },
    { value: 'إعاقة حركية', label: 'إعاقة حركية', type: mobility },
    { value: 'إعاقة ذهنية', label: 'إعاقة ذهنية', type: intellectual },
    { value: 'اضطراب طيف التوحد', label: 'اضطراب طيف التوحد', type: [] },
    { value: 'اضطرابات تواصل', label: 'اضطرابات تواصل', type: [] },
    { value: 'قصور الانتباه وفرط الحركة', label: 'قصور الانتباه وفرط الحركة', type: [] },
    { value: 'اضطرابات التعلم المحددة', label: 'اضطرابات التعلم المحددة', type: [] },
    { value: 'اضطرابات التعلم المحددة', label: 'اضطرابات التعلم المحددة', type: [] },
    { value: 'الاضطرابات النفسية والانفعالية', label: 'الاضطرابات النفسية والانفعالية', type: physiologicalimpaired },
]
export const serviceTypes = [
    { value: 'دورات تدريبية', label: 'دورات تدريبية' },
    { value: 'ورش', label: 'ورش' },
    { value: 'توظيف', label: 'توظيف' },

]
export const disEnabled = [
    { value: 'إتاحة بصرية', label: 'إتاحة بصرية' },
    { value: 'إتاحة سمعية', label: 'إتاحة سمعية' },
    { value: 'إتاحة حركية', label: 'إتاحة حركية' },
    { value: 'إتاحة كلامية', label: 'إتاحة كلامية' },
    { value: 'إتاحة ذهنية', label: 'إتاحة ذهنية' },
]
export const sendNotTo = [
    { value: 'pwdUsers', label: 'المستخدمين', types: disTypes },
    { value: 'contentCreators', label: 'المدونين', types: [] },

]
export const sex = [
    { _id: 'ذكر', name: 'ذكر' },
    { _id: 'أنثى', name: 'أنثى' },
]

export const maritalStatus = [
    { value: 'أعزب', label: 'أعزب' },
    { value: 'متزوج', label: 'متزوج' },
    { value: 'أرمل', label: 'أرمل' },
    { value: 'مطلق', label: 'مطلق' },
]
export const nationalities = [
    { value: 'مصري', label: 'مصري' },
]
export const martialServiceStatus = [
    { value: 'معاف', label: 'معاف' },
    { value: 'أديت الخدمة', label: 'أديت الخدمة' },
]
export const socialInsuranceStatus = [
    { value: 'مؤمن علية', label: 'مؤمن علية' },
    { value: 'غير مؤمن عليه', label: 'غير مؤمن علية' },
]
export const contactRelations = [
    { value: 'أب', label: 'أب' },
    { value: 'أم', label: 'أم' },
    { value: 'أخ', label: 'أخ' },
    { value: 'أخت', label: 'أخت' },
    { value: 'عم', label: 'عم' },
    { value: 'عمة', label: 'عمة' },
    { value: 'خال', label: 'خال' },
    { value: 'خالة', label: 'خالة' },
]
export const pwdEducation = [
    { value: 'بدون', label: 'بدون', type: false },
    { value: 'مؤهل متوسط', label: 'مؤهل متوسط', type: [{ value: 'دبلوم', label: 'دبلوم' }] },
    {
        value: 'مؤهل عالي', label: 'مؤهل عالي', type: [
            { value: 'بكالوريوس', label: 'بكالوريوس' },
            { value: 'ليسانس', label: 'ليسانس' },
            { value: 'ماجيستير', label: 'ماجيستير' },
            { value: 'دكتوراه', label: 'دكتوراه' },
        ]
    },
]

export const trainingSectors = [
    { value: 'اختبار', label: 'اختبار' }
]
export const specialites = [
    { value: 'اختبار', label: 'اختبار' }

]
export const certificateTypes = [
    { value: 'دبلومة', label: 'دبلومة' },
    { value: 'شهاده حضور', label: 'شهاده حضور' },
]
export const salary = [
    { value: 3000, label: 'من 1500 إلى 3000 ج.م' },
    { value: 5000, label: 'من 3000 إلى 5000 ج.م' },
    { value: 'more', label: 'اكثر من 5000 ج.م' },
]
export const experinces = [
    { value: 1, label: 'خريج' },
    { value: 2, label: 'مبتدئ' },
    { value: 4, label: 'متوسط' },
    { value: 5, label: 'متقدم' },
    { value: 'more', label: 'خبير' },

]

export const pwdSuitables = [
    { value: true, label: 'تصلح له', color: '#63d09c' },
    { value: false, label: 'لا تصلح له', color: '#ea6969' }
]
export const pwdStatus = [
    { value: true, label: 'قبول', color: '#5fc0e9' },
    { value: false, label: 'رفض', color: '#ea6969' }
]

export const status = [
    { value: true, label: 'نعم', color: '#5fc0e9' },
    { value: false, label: 'لا', color: '#ea6969' }
]

export const pwdHire = [
    { value: true, label: 'يتم تعيينه', color: '#5fc0e9' },
    { value: false, label: 'لا يتم تعيينه', color: '#ea6969' }
]
