import { useEffect, useState } from 'react'
import './css/toolbar.css'
import { TextToolbar } from './index'
export function Toolbar(props) {

    const { right, left, black, center } = props
    const [width, setWidth] = useState(window.innerWidth)
    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth)
        }
        window.addEventListener('resize', handleResize)
        return _ => {
            window.removeEventListener('resize', handleResize)
        }
    })
    const b = black ? true : false
    const c = width <= 992 ? true : false
    const Class =center? 'toolbar-center':( c ? 'toolbar-center' : 'toolbar')
    return (
        <div className={Class}>
            {right && <div style={{ width: c ? '100%' : 'auto' }} >

                <TextToolbar black={b} center={c}>
                    {right}
                </TextToolbar>

            </div>
            }
            {center &&
                    <TextToolbar black={b} center>
                        {center}
                    </TextToolbar>
            }
            { left}
        </div >
    );
}

