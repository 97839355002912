import './css/sidenav.css'
import Badge from 'react-bootstrap/Badge'
import { useEffect, useState } from 'react'
import { CatCheckBox, CSelect } from './inputs'
import { TextCardSubtitle, TextCardTitle, TextRegSub } from './text'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBriefcase, faDollarSign, faMapMarked, faMapMarkerAlt, faStar, faThLarge } from '@fortawesome/free-solid-svg-icons'
import { Button } from './button'
export function SideNav({ navs, nav, currentNav }) {
    const [active, setActive] = useState(currentNav ? currentNav : navs[0])
    const [value, setValue] = useState(currentNav ? { value: currentNav.tab, label: currentNav.name } : navs.map(n => { return { value: n.tab, label: n.name } })[0])
    useEffect(() => {
        if (currentNav) setActive(currentNav)
    }, [currentNav])
    const setNav = (tab) => {
        setActive(tab)
        nav(tab)
    }
    const setSelect = (v) => {
        setValue(v)
        nav(v)
    }
    return (
        <div>
            <div className='nav-container'>
                {navs.map((nav, i) => {
                    const a = nav.disabled ? '-disabled' : (nav.tab === active.tab) ? '-active' + (i === 0 ? '-first' : ((i === navs.length - 1) ? '-last' : '')) : ''
                    return (
                        <div key={i} onClick={nav.onClick ? nav.onClick : () => { if (!nav.disabled) setNav(nav) }} className={'nav-box' + a}  >
                            {nav.name} <Badge variant="primary">{nav.badges > 0 && nav.badges}</Badge>
                        </div>
                    )
                })}
            </div>
            <div className='mobile-nav-container'>
                <CSelect menu value={value} isOptionDisabled={option => option.disabled} onChange={setSelect} options={navs.map(n => { return { value: n.tab, label: n.name, ...n } })} margin defaultValue={value} />
            </div>
        </div>


    )
}
export function CategoryNav({ title, options, sort, lang, type }) {
    const [active, setActive] = useState([])
    const [size, setSize] = useState(5)

    const Select = (type) => {
        if (!active.find(value => value === type)) setActive([...active, type])
        else {
            const newActive = [...active]
            const index = newActive.indexOf(type);
            if (index > -1) {
                newActive.splice(index, 1);
            }
            setActive(newActive)
        }
    }
    useEffect(() => {
        sort({ type: type, value: active })
    }, [active])
    return (
        <div className='category-nav-container' >
            <div className='category-nav-title'>
                {title}
            </div>
            <div className='category-nav-options'>
                {options.slice(0, size).map(type =>
                    <div>
                        <CatCheckBox onClick={() => Select(type.name)} active={active.find(value => value === type.name)}>
                            <div>{type.name} <span style={{ color: '#5fc0e9' }}>({type.jobNumbers})</span> </div>
                        </CatCheckBox>
                    </div>
                )}
                {options.length > 5 && <div className='category-nav-more' onClick={() => setSize(options.length)} >
                    {size > 5 ? '' : lang.showMore}
                </div>}
            </div>
        </div>
    )
}

export function JobDetails({ job, lang, onClick }) {

    return (
        <div className='category-nav-container' >
            <div className='category-nav-title'>
                {lang.jobTitle}
            </div>
            <div className='category-nav-options'>
                <div className='pb-3'>
                    <img style={{
                        width: '100%',
                        height: '200px',
                        borderRadius: '15px',
                    }} src={lang.baseUrl + '/media/imgs/' + job.img} alt={''} loading="lazy"/>
                </div>
                <TextCardTitle align="center" >
                    {job.jobTitle}
                </TextCardTitle>
                <TextCardSubtitle color='#5fc0e9' align="center">
                    {job.companyId && job.companyId.name}
                </TextCardSubtitle>
                <div className='pt-3' >

                    <TextCardSubtitle margin color='#000000' align="right">
                        <i><FontAwesomeIcon icon={faDollarSign} size='sm' color='#ff4136' /></i> {lang.salary} {job.minSalary} {':'} {job.maxSalary} {lang.pound}
                    </TextCardSubtitle>
                    <TextCardSubtitle margin color='#000000' align="right">
                        <a href={job.companyId && job.companyId.location} target="_blank">
                            <i ><FontAwesomeIcon icon={faMapMarkerAlt} size='sm' color='#ff4136' /></i> {job.companyId && job.companyId.address}
                        </a>
                    </TextCardSubtitle>
                    <TextCardSubtitle margin color='#000000' align="right">
                        <i><FontAwesomeIcon icon={faStar} size='sm' color='#ff4136' /></i> {lang.experince} {job.minExperience} {lang.to} {job.maxExperience} {lang.years}
                    </TextCardSubtitle>
                    <TextCardSubtitle margin color='#000000' align="right">
                        <i><FontAwesomeIcon icon={faThLarge} size='sm' color='#ff4136' /></i> {job.workField}
                    </TextCardSubtitle>
                </div>
                {job.status ? <Button disabled margin type='outlined' size='sm' fullWidth text={lang.jobApplied} />
                    :
                    <Button onClick={onClick} margin type='secondary' size='sm' fullWidth text={lang.apply} />
                }
            </div>
        </div>
    )
}

export function ServiceDetails({ service, lang, onClick }) {

    return (
        <div className='category-nav-container' >
            <div className='category-nav-title'>
                {lang.jobTitle}
            </div>
            <div className='category-nav-options'>
                <div className='pb-3'>
                    <img style={{
                        width: '100%',
                        height: '200px',
                        borderRadius: '15px',
                    }} src={lang.baseUrl + '/media/imgs/' + service.img} alt={''} loading="lazy"/>
                </div>
                <TextCardTitle align="center" >
                    {service.name}
                </TextCardTitle>

                <div className='pt-3' >
                    <TextCardSubtitle margin color='#000000' align="right">
                        <i><FontAwesomeIcon icon={faMapMarkerAlt} size='sm' color='#ff4136' /></i>  {service.organizationId.name}
                    </TextCardSubtitle>

                    <TextCardSubtitle margin color='#000000' align="right">
                        <i><FontAwesomeIcon icon={faDollarSign} size='sm' color='#ff4136' /></i> {lang.serviceFees} {service.amount} {lang.pound}
                    </TextCardSubtitle>


                    <TextCardSubtitle margin color='#000000' align="right">
                        <i><FontAwesomeIcon icon={faThLarge} size='sm' color='#ff4136' /></i> {service.type}
                    </TextCardSubtitle>
                </div>
                {service.status ? <Button disabled margin type='outlined' size='sm' fullWidth text={lang.jobApplied} />
                    :
                    <Button onClick={onClick} margin type='secondary' size='sm' fullWidth text={lang.apply} />
                }
            </div>
        </div>
    )
}
export function OrgDetails({ org, lang }) {
    return (
        <div className='category-nav-container' >
            <div className='category-nav-title'>
                {org.name}
            </div>
            <div className='category-nav-options'>
                <div className='pb-3'>
                    <img style={{
                        width: '100%',
                        height: '200px',
                        borderRadius: '15px',
                    }} src={lang.baseUrl + '/org/media/imgs/' + org.avatar} alt={''} loading="lazy"/>
                </div>
                <TextCardTitle align="center" >
                    {org.name}
                </TextCardTitle>

                <div className='pt-3' >

                    <TextRegSub right>
                        <a href={org.location} target="_blank">
                            <i><FontAwesomeIcon icon={faMapMarked} size='sm' color='#ff4136' /></i> {org.address}
                        </a>
                    </TextRegSub>

                    <TextRegSub right>

                        <i><FontAwesomeIcon icon={faThLarge} size='sm' color='#ff4136' /></i> {org.industry && org.industry.name}
                    </TextRegSub>

                    {/* <TextRegSub right>
                        <i><FontAwesomeIcon icon={faBriefcase} size='sm' color='#ff4136' /></i> {lang.services}{':'} {org.services}
                    </TextRegSub>
                    <TextRegSub right>
                        <i><FontAwesomeIcon icon={faBriefcase} size='sm' color='#ff4136' /></i> {lang.jobs}{':'} {org.jobs}
                    </TextRegSub> */}
                </div>

            </div>
        </div>
    )
}
export function CoDetails({ co, lang, activeJobs }) {
    return (
        <div className='category-nav-container' >
            <div className='category-nav-title'>
                {co.name}
            </div>
            <div className='category-nav-options'>
                <div className='pb-3'>
                    <img style={{
                        width: '100%',
                        height: '200px',
                        borderRadius: '15px',
                    }} src={lang.baseUrl + '/co/media/imgs/' + co.avatar} alt={''} loading="lazy"/>
                </div>
                <TextCardTitle align="center" >
                    {co.name}
                </TextCardTitle>

                <div className='pt-3' >

                    <TextRegSub right>
                        <a href={co.location} target="_blank">
                            <i><FontAwesomeIcon icon={faMapMarked} size='sm' color='#ff4136' /></i> {co.address}
                        </a>
                    </TextRegSub>

                    <TextRegSub right>

                        <i><FontAwesomeIcon icon={faThLarge} size='sm' color='#ff4136' /></i> {co.industry && co.industry.name}
                    </TextRegSub>
                    <TextRegSub right>
                        <i><FontAwesomeIcon icon={faBriefcase} size='sm' color='#ff4136' /></i> {lang.jobs}{':'} {activeJobs.length}
                    </TextRegSub>
                </div>

            </div>
        </div>
    )
}