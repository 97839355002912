import axios from "axios";
import { Alert } from "../Components";

function logout() {
  sessionStorage.clear();
}
export const request = async ({
  url,
  data,
  method,
  onUploadProgress,
  next,
  mute,
  muteSuccess,
}) => {
  let req = await axios({
    method: method,
    url: url,
    data: data,
    onUploadProgress,
    timeout: 10000,
  })
    .then((response) => {
      if (response.data.success) {
        !mute &&
          !muteSuccess &&
          Alert({ msg: response.data.msg, type: "success", toast: true });
        next && next();
        if (response.data.data) return response.data.data;
        else return response.data;
      }
      if (!response.data.success) {
        if (method === "post" && !mute)
          Alert({ msg: response.data.msg, type: "warning" });
      }
      return response.data;
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) return logout(); //
      else return { success: false, error: true, msg: error.message };
    });
  return req;
};
// functions
export const AdminUserApi = async (token) => {
  axios.defaults.headers.common["x-access-token"] = token || false;
  return request({ url: "/admin/api/profile", method: "get" });
};

export const downloadFile = async ({ url, name, lang }) => {
  axios({
    method: "get",
    url: url,
    responseType: "arraybuffer",
  })
    .then((response) => {
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/octet-stream" })
      );
      link.download = name;
      document.body.appendChild(link);

      link.click();
      setTimeout(function () {
        window.URL.revokeObjectURL(link);
      }, 200);
    })
    .catch((error) => {
      if (error.response.status === 580)
        return Alert({ type: "warning", msg: lang.notAllowed });
    });
};
export const getImg = ({ url, lang }) => {
  return axios({
    method: "get",
    url: url,
    responseType: "blob",
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response.status === 580)
        return Alert({ type: "warning", msg: lang.notAllowed });
    });
};
export async function UploadImgsApi({ file }, onUploadProgress) {
  let data = new FormData();
  data.append("img", file);
  return await request({
    data,
    url: "/upload-img",
    method: "post",
    onUploadProgress,
  });
}
export function AdminLoginApi(data) {
  return request({ data, url: "/admin/login", method: "post" });
}

export const AdminStatisticsApi = async () => {
  return request({ url: "/admin/api/statistics", method: "get" });
};

// pwd
export const AdminPwdApi = async () => {
  return request({ url: "/admin/api/pwd", method: "get" });
};
export const AdminPwdStatusApi = async (data) => {
  return request({ url: "/admin/api/pwd-status", data, method: "post" });
};
// co
export const AdminCoApi = async () => {
  return request({ url: "/admin/api/companies", method: "get" });
};
export const AdminCoStatusApi = async (data) => {
  return request({ url: "/admin/api/companies-status", data, method: "post" });
};
export function AdminCoTerminatedJobsApi() {
  return request({ url: "/admin/api/terminated-jobs", method: "get" });
}
export function AdminCoActiveJobsApi() {
  return request({ url: "/admin/api/active-jobs", method: "get" });
}
export function AdminCompanyActiveJobsApi(co_id) {
  if (!co_id) {
    return [];
  }
  return request({
    url: `/admin/api/company-active-jobs?coId=${co_id}`,
    method: "get",
  });
}
export function AdminActivateTerminateCoJobApi({ id, initData, terminate }) {
  const data = { id: id };
  const url = terminate
    ? "/admin/api/terminate-job"
    : "/admin/api/activate-job";
  request({ data, url: url, method: "post", next: initData });
} ///added condition to activate job after termination
// Admin can edit article image uploaded by company
export async function CoUploadImgsApi({ file }, onUploadProgress) {
  let data = new FormData();
  data.append("img", file);
  return await request({
    data,
    url: "/co/api/upload-img",
    method: "post",
    onUploadProgress,
  });
}

// org
export const AdminOrgApi = async () => {
  return request({ url: "/admin/api/organizations", method: "get" });
};
export const AdminOrgStatusApi = async (data) => {
  return request({
    url: "/admin/api/organizations-status",
    data,
    method: "post",
  });
};
export const AdminOrgTerminatedServicesApi = async () => {
  return request({ url: "/admin/api/terminated-services", method: "get" });
};
export const AdminOrgActiveServicesApi = async () => {
  return request({ url: "/admin/api/active-services", method: "get" });
};
export function AdminActivateHoldOrgServiceApi({ id, initServices, hold }) {
  const data = { id: id };
  const url = hold ? "/admin/api/hold-service" : "/admin/api/activate-service";
  request({ data, url: url, method: "post", next: initServices });
}
export function PwdPrfileApi(id) {
  const data = { pwdId: id };
  return request({ data, url: "/pwd/profile", method: "post", mute: true });
}
// Admin can edit article image uploaded by organization
export async function OrgUploadImgsApi({ file }, onUploadProgress) {
  let data = new FormData();
  data.append("img", file);
  return await request({
    data,
    url: "/org/api/upload-img",
    method: "post",
    onUploadProgress,
  });
}

// settings
export function AdminResetPassowrdApi(data) {
  return request({ url: "/admin/api/reset-password", method: "post", data });
}
export function AdminResetPwdPassApi(data) {
  return request({ url: "/admin/api/resetpwd-pass", method: "post", data });
}
//neww
export function AdminResetOrgPassApi(data) {
  return request({ url: "/admin/api/resetorg-pass", method: "post", data });
}
//neww
export function AdminResetCoPassApi(data) {
  return request({ url: "/admin/api/resetco-pass", method: "post", data });
}
//neww

export async function AdminAvatarApi(file, onUploadProgress) {
  let data = new FormData();

  data.append("img", file.file);
  return await request({
    data,
    url: "/admin/api/upload-avatar",
    method: "post",
    onUploadProgress,
  });
}

// roles
export const AdminsApi = async () => {
  return request({ url: "/admin/api/admins", method: "get" });
};
export const AdminRolesApi = async () => {
  return request({ url: "/admin/api/roles", method: "get" });
};
export const AdminCreateRolesApi = async (data) => {
  return request({ url: "/admin/api/roles/create", method: "post", data });
};
export const AdminEditRolesApi = async (data) => {
  return request({ url: "/admin/api/roles/edit", method: "post", data });
};
//route of deleting pwd user with his associated data in other collections //Heba

export const AdminRemovePwdUserApi = async ({ userId }) => {
  const data = { userId: userId };
  return request({
    url: "/admin/api/roles/remove-pwduser",
    method: "post",
    data,
  });
};
export const AdminRemoveUserApi = async ({ userId, initData }) => {
  const data = { userId: userId };
  return request({
    url: "/admin/api/roles/remove",
    method: "post",
    data,
    next: initData,
  });
};

export async function AdminUploadImgsApi({ file }, onUploadProgress) {
  let data = new FormData();
  data.append("img", file);
  return await request({
    data,
    url: "/admin/api/upload-img",
    method: "post",
    onUploadProgress,
  });
}
// articles
export function AddArticleApi({ data, tab }) {
  request({
    data,
    url: "/admin/api/add-article",
    method: "post",
    next: tab(3),
  });
}
export function AddVideoApi({ data, tab }) {
  request({
    data,
    url: "/admin/api/add-article-video",
    method: "post",
    next: tab(3),
  });
}
export function EditArticleApi({ data, initData }) {
  request({
    data,
    url: "/admin/api/edit-article",
    method: "post",
    next: initData,
  });
}

export function RemoveArticleApi({ id, initData }) {
  const data = { id: id };
  request({
    data,
    url: "/admin/api/remove-article",
    method: "post",
    next: initData,
  });
}

export function GetArticlesApi(admins) {
  const url = admins ? "/admin/api/articles" : "/admin/api/articles/users";
  return request({ url: url, method: "get" });
}
export async function UploadVideoApi({ file }, onUploadProgress) {
  let data = new FormData();
  data.append("video", file);
  return await request({
    data,
    url: "/admin/api/upload-video",
    method: "post",
    onUploadProgress,
  });
}
export function ArticleCommentsApi(data) {
  return request({
    url: "/articles/comments",
    method: "post",
    data,
    mute: true,
  });
}
export function ArticleCommentApi(data) {
  return request({
    url: "/admin/api/article/comment",
    method: "post",
    data,
    mute: true,
  });
}
export function DeleteCommentApi({ data, initData }) {
  return request({
    url: "/admin/api/article/delete-comment",
    method: "post",
    data,
    next: initData,
  });
}
// contact
export function SendMessageApi(data) {
  return request({
    url: "/admin/api/messages/send",
    method: "post",
    data,
    mute: true,
  });
}
export function MessageApi(data) {
  return request({
    url: "/admin/api/messages",
    method: "post",
    data,
    mute: true,
  });
}
export function MsgSeenApi(data) {
  return request({
    url: "/admin/api/message/seen",
    method: "post",
    data,
    mute: true,
  });
}
export function VCallApi(data) {
  return request({
    url: "/admin/api/messages/call",
    method: "post",
    data,
    muteSuccess: true,
  });
}
export function MsgsContactsApi() {
  return request({
    url: "/admin/api/messages/contacts",
    method: "get",
    mute: true,
  });
}
// ----------------------------------------------------------------------------

// notifications
export function NotificationsApi() {
  return request({ url: "/admin/api/notifications", method: "get" });
}
export function GetMessagesApi() {
  return request({ url: "/admin/api/messages", method: "get" });
}

export function NotificationSeenApi(id) {
  const data = { notificatonId: id };
  return request({
    url: "/admin/api/notification-seen",
    method: "post",
    data,
    mute: true,
  });
}

// reports

export function ReportsCompaniesApi(data, pageNumber) {
  return request({
    url: `/admin/api/reports/companies?page=${pageNumber}`,
    method: "post",
    data,
    mute: true,
  });
}
export function ReportsOrgsApi(data, pageNumber) {
  return request({
    url: `/admin/api/reports/organizations?page=${pageNumber}`,
    method: "post",
    data,
    mute: true,
  });
}
export function ReportsPwdApi(data, pageNumber) {
  return request({
    url: `/admin/api/reports/pwd?page=${pageNumber}`,
    method: "post",
    data,
    mute: true,
  });
}
export function ReportsJobsApi(data, pageNumber) {
  return request({
    url: `/admin/api/reports/jobs?page=${pageNumber}`,
    method: "post",
    data,
    mute: true,
  });
}
export function ReportsServicesApi(data, pageNumber) {
  return request({
    url: `/admin/api/reports/services?page=${pageNumber}`,
    method: "post",
    data,
    mute: true,
  });
}
export function ReportsAnalysisApi(data, pageNumber) {
  // new method for pwd analysis in admin
  return request({
    url: `/admin/api/reports/analysis?page=${pageNumber}`,
    method: "post",
    data,
    mute: true,
  });
}

// managements
export function GetMainInfoApi() {
  return request({ url: "/data/maininfo", method: "get", mute: true });
}
// managements
export function SetMainInfoApi(data) {
  return request({ url: "/admin/api/maininfo", method: "post", data });
}
// countries
export function CountryApi(data) {
  return request({ url: "/admin/api/countries", method: "post", data });
}
export function AddCountryStateApi(data) {
  return request({
    url: "/admin/api/countries-state-add",
    method: "post",
    data,
  });
}
export function EditCountryStateApi(data) {
  return request({
    url: "/admin/api/countries-state-edit",
    method: "post",
    data,
  });
}
export function CountriesApi(data) {
  return request({ url: "/data/countries", method: "get", data });
}

// disabilites
export function DisabilityApi(data) {
  return request({ url: "/admin/api/disabilities", method: "post", data });
}
export function AddDisabilityTypeApi(data) {
  return request({
    url: "/admin/api/disabilities-type-add",
    method: "post",
    data,
  });
}
export function EditDisabilityTypeApi(data) {
  return request({
    url: "/admin/api/disabilities-type-edit",
    method: "post",
    data,
  });
}
export function DisabilitiesApi(data) {
  return request({ url: "/data/disabilities", method: "get", data });
}

// trainingSectors
export function TrainingApi(data) {
  return request({ url: "/admin/api/trainingsectors", method: "post", data });
}
export function AddTrainingTypeApi(data) {
  return request({
    url: "/admin/api/trainingsectors-type-add",
    method: "post",
    data,
  });
}
export function EditTrainingTypeApi(data) {
  return request({
    url: "/admin/api/trainingsectors-type-edit",
    method: "post",
    data,
  });
}
export function TrainingsApi(data) {
  return request({ url: "/data/trainingsectors", method: "get", data });
}

// educations
export function EducationApi(data) {
  return request({ url: "/admin/api/educations", method: "post", data });
}
export function AddEducationTypeApi(data) {
  return request({
    url: "/admin/api/educations-type-add",
    method: "post",
    data,
  });
}
export function EditEducationTypeApi(data) {
  return request({
    url: "/admin/api/educations-type-edit",
    method: "post",
    data,
  });
}
export function AddEducationCertApi(data) {
  return request({
    url: "/admin/api/educations-cert-add",
    method: "post",
    data,
  });
}
export function EditEducationCertApi(data) {
  return request({
    url: "/admin/api/educations-cert-edit",
    method: "post",
    data,
  });
}
export function EducationsApi(data) {
  return request({ url: "/data/educations", method: "get", data });
}

// Skills
export function SkillApi(data) {
  return request({ url: "/admin/api/skills", method: "post", data });
}
export function SkillsApi(data) {
  return request({ url: "/data/skills", method: "get", data });
}

// organization Fields
export function OrgFieldeApi(data) {
  return request({
    url: "/admin/api/organizationfields",
    method: "post",
    data,
  });
}
export function OrgFieldsApi(data) {
  return request({ url: "/data/organizationfields", method: "get", data });
}
// company Fields
export function CoFieldeApi(data) {
  return request({ url: "/admin/api/companyfields", method: "post", data });
}
export function CoFieldsApi(data) {
  return request({ url: "/data/companyfields", method: "get", data });
}
// organizatio Fields
export function OrgTypeApi(data) {
  return request({ url: "/admin/api/organizationtypes", method: "post", data });
}
export function OrgTypesApi(data) {
  return request({ url: "/data/organizationtypes", method: "get", data });
}

// work fields
export function WorkfieldApi(data) {
  return request({ url: "/admin/api/workfields", method: "post", data });
}
export function AddWorkfieldTypeApi(data) {
  return request({
    url: "/admin/api/workfields-type-add",
    method: "post",
    data,
  });
}
export function EditWorkfieldTypeApi(data) {
  return request({
    url: "/admin/api/workfields-type-edit",
    method: "post",
    data,
  });
}
export function WorkFieldsApi(data) {
  return request({ url: "/data/workfields", method: "get", data });
}

// Jobs Info
export function JobInfoApi(data) {
  return request({ url: "/admin/api/jobsinfo", method: "post", data });
}
export function JobsInfoApi(data) {
  return request({ url: "/data/jobsinfo", method: "get", data });
}
// mailing list
export function MailsListApi(data) {
  return request({ url: "/admin/api/mailslist", method: "get", data });
}
// contact us
export function GetContactUsMsgsApi() {
  return request({ url: "/admin/api/contact-us", method: "get" });
}
export function ContactUsSeen(data) {
  return request({
    url: "/admin/api/contact-us/seen",
    method: "post",
    data,
    mute: true,
  });
}
// tech problems and suggestions
export function GetTechProblemsAndSuggestionsApi() {
  return request({ url: "/admin/api/techProb-suggestions", method: "get" });
}
export function TechProblemsAndSuggestionsSeen(data) {
  return request({
    url: "/admin/api/techProb-suggestions/seen",
    method: "post",
    data,
    mute: true,
  });
}
