import * as yup from "yup";
import { ar } from '../Assets/Languages';
export const loginValidationSchema = yup.object({
    input: yup.string()
        .required(ar.usernameRequired)
        .min(4, ar.usernameLength)
        .max(50, ar.usernameLength)
        .matches(/^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 . ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 . ]$/, ar.usernameError),
    password: yup.string()
        .required(ar.passwordRequired)
        .min(8, ar.passwordLength)
        .max(20, ar.passwordLength)
        .matches(/^(?=.*[\w])(?=.*[\W])[\w\W]{8,}$/, ar.passwordError)  
})

export const editSettingsSchema = yup.object({
    currentPass: yup.string()
        .required(ar.passwordRequired)
        .min(8, ar.passwordLength)
        .max(20, ar.passwordLength)
        .matches(/^(?=.*[\w])(?=.*[\W])[\w\W]{8,}$/, ar.passwordError),
    password: yup.string()
        .required(ar.passwordRequired)
        .min(8, ar.passwordLength)
        .max(20, ar.passwordLength)
        .matches(/^(?=.*[\w])(?=.*[\W])[\w\W]{8,}$/, ar.passwordError),
    passConfirm: yup.string()
        .required(ar.passwordRequired)  
        .oneOf([yup.ref('password')], ar.passError),
})

export const addEditRoleValidationSchema = yup.object({
    name: yup.string()
        .required(ar.adminRoleNameٌRequired)
        .min(1, ar.adminRoleNameLength)
        .max(20, ar.adminRoleNameLength)
        .matches(/^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 . ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 . ]$/, ar.adminRoleNameError),
    role: yup.string()
        .required(ar.adminRoleRequired),
    username: yup.string()
        .required(ar.adminRoleUsernameRequired)
        .min(1, ar.adminRoleUsernameLength)
        .max(50, ar.adminRoleUsernameLength)
        .matches(/^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 . ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 . ]$/, ar.adminRoleUsernameError),
    password: yup.string()
        .required(ar.adminRolePasswordRequired)
        .min(8, ar.adminRolePasswordError)
        .max(20, ar.adminRolePasswordError)
        .matches(/^(?=.*[\w])(?=.*[\W])[\w\W]{8,}$/, ar.adminRolePasswordError),
    passConfirm: yup.string()
        .required(ar.adminRolePasswordRequired)  
        .oneOf([yup.ref('password')], ar.passError),  
})

export const addEditArticleValidationSchema = yup.object({
    title: yup.string()    
        .required(ar.articleTitleRequired)
        .min(1, ar.articleTitleError)
        .max(100, ar.articleTitleLength)
        .matches(/^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 . , ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 . , ]$/, ar.articleTitleError),
    imageDescription: yup.string()    
        .required(ar.imageDescriptionRequired)
        .min(1, ar.imageDescriptionLength)
        .max(100, ar.imageDescriptionLength)
        .matches(/^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 . , ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 . , ]$/, ar.imageDescriptionError),
    body: yup.string()
        .required(ar.articleAboutRequired)
        .min(4, ar.articleِAboutLength)
        .max(3000, ar.articleِAboutLength)
        .matches(/^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 \n . , ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 \n . , ]$/, ar.articleAboutError)
})

export const addEditVideosValidationSchema = yup.object({
    youtube: yup.string()    
        .required(ar.youtubeLinkRequired),
    title: yup.string()    
        .required(ar.videoTitleRequired)
        .min(1, ar.videoTitleLength)
        .max(100, ar.videoTitleLength)
        .matches(/^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 . , ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 . , ]$/, ar.videoTitleError),
    imageDescription: yup.string()    
        .required(ar.imageDescriptionRequired)
        .min(1, ar.imageDescriptionLength)
        .max(100, ar.imageDescriptionLength)
        .matches(/^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 . , ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 . , ]$/, ar.imageDescriptionError),
    body: yup.string()
        .required(ar.videoDescRequired)
        .min(4, ar.videoDescLength)
        .max(3000, ar.videoDescLength)
        .matches(/^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 . , \n ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 . , \n ]$/, ar.videoDescError),           
})

export const addCommentValidationSchema = yup.object({
    comment: yup.string()
        .required(ar.commentRequired)
        .min(1, ar.commentLength)
        .max(100, ar.commentLength)
        .matches(/^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 \n . , ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 \n . , ]$/, ar.commentError),
})

export const sendMsgValidationSchema = yup.object({
    message: yup.string()
        .required(ar.msgError)
        .min(1, ar.msgLength)
        .max(500, ar.msgLength)
        .matches(/^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 . , ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 . , ]$/, ar.msgError)
})

export const mainDataAddEditValidationSchema = yup.object({
    linkedin: yup.string()
        .nullable().transform((value) => !!value ? value : null)
        .matches(/^(ftp|http|https):\/\/?(?:www\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/, ar.urlError),        
    facebook: yup.string()
        .nullable().transform((value) => !!value ? value : null)
        .matches(/^(https?:\/\/)?((w{3}\.)?)facebook.com\/.*/, ar.urlError),        
    twitter: yup.string()
        .nullable().transform((value) => !!value ? value : null)
        .matches(/^(ftp|http|https):\/\/?(?:www\.)?twitter.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/, ar.urlError),        
    youtube: yup.string()
        .nullable().transform((value) => !!value ? value : null)
        .matches(/^(ftp|http|https):\/\/?(?:www\.)?youtube.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/, ar.urlError),
    email: yup.string()
        .required(ar.emailRequired)  
        .matches(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, ar.emailError),  
    phone: yup.string()
        .required(ar.phoneRequired)
        .matches(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/, ar.phoneError),
    mobile: yup.string()
        .nullable().transform((value) => !!value ? value : null)
        .matches(/^01[0125][0-9]{8}$/, ar.phoneError),
    fax: yup.string()
        .nullable().transform((value) => !!value ? value : null)
        .matches(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/, ar.faxError),    
    address: yup.string()
        .required(ar.fullAddressRequired)
        .matches(/^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9]$/, ar.fullAddressError),        
    copyright: yup.string()
        .required(ar.copyrightRequired)
        .matches(/^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9., ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9., ]$/, ar.copyrightError),            
})

export const adminResetUserPassValidationSchema = yup.object({
    password: yup.string()
        .required(ar.passwordRequired)
        .min(8, ar.passwordLength)
        .max(20, ar.passwordLength)
        .matches(/^(?=.*[\w])(?=.*[\W])[\w\W]{8,}$/, ar.passwordError)  
})
export const dateValidationSchema = yup.object().shape({
    from: yup.date()
        .nullable()
        .transform((curr, orig) => orig === '' ? null : curr),
    to: yup.date()
        .nullable()
        .transform((curr, orig) => orig === '' ? null : curr)
        .min(yup.ref("from"),ar.toDateError),
})

export const reportAnalysisValidationSchema = yup.object().shape({
    from: yup
        .number()
        .typeError(ar.ageIsNumberError)
        .nullable()
        .transform(value => {
            if (value === "") return null;
            return parseFloat(value);
        })
        .default(null),
    to: yup
        .number()
        .typeError(ar.ageIsNumberError)
        .nullable()
        .transform(value => {
            if (value === "") return null;
            return parseFloat(value);
        })
        .min(yup.ref("from"), ar.ageError)
        .default(null),
    orgUniqueCode: yup
        .string()
        .nullable()
        .transform((value) => !!value ? value : null) // nullable makes the value to accept null also. transform callback returns the value as null if value is empty.
        .min(13, ar.orgUniqueCodeLength)
        .max(13, ar.orgUniqueCodeLength)
        .matches(/^npd-\d{4}-\w{4}$/, ar.orgUniqueCodeError),    
  })
