import { Row, Col, Container } from 'react-bootstrap'
import './css/tabbar.css'
export function TabBar({ options, active, tab,  }) {
    return (
        <div className='tabbar-container'>
            <Container>
                <Row >
                    {options && options.map((data, index) => (
                        <Col key={index} onClick={() => !data.disabled && tab(data.tab)} className={data.tab === active ? 'tabbar-box-active'  : 'tabbar-box'+ (data.disabled? '-disabled' :'')}>
                            <div className='tabbar-title'>
                                {data.name}
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    )
}