import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Col, Container, Row, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import "./css/table.css";
import { useState } from "react";

export const Table = ({
  tab = null, //if i didn't send the tab probs make it as null //Heba
  lang,
  columns,
  data = [],
  allData = [],
  total,
  pageNumber,
  updatePreviousPage,
  updateNextPage,
  reQuery,
  btnStyle,
}) => {
  // make show all state to be true when click on showAll button //Heba
  const [showAll, setShowAll] = useState(false);

  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };

    const handleShowAllClick = () => {
      // toggle the showAll state when clicked on this button //Heba
      setShowAll((prevShowAll) => !prevShowAll);
    };
    return (
      <>
        <Row>
          <Col
            //style according the opening tab //Heba

            xs={tab === 6 ? "3" : "4"}
            lg={tab === 6 ? "3" : "4"}
            md={tab === 6 ? "3" : "4"}
            className="m-4"
          >
            <Button
              onClick={reQuery}
              variant="outline-secondary"
              style={btnStyle}
            >
              {lang.reQuery}
            </Button>
          </Col>
          <Col
            //style according the opening tab //Heba
            xs={tab === 6 ? "3" : "4"}
            lg={tab === 6 ? "3" : "4"}
            md={tab === 6 ? "3" : "4"}
            className="m-4"
          >
            <Button
              onClick={handleClick}
              variant="outline-secondary"
              style={btnStyle}
            >
              {lang.exportAsCSV}
            </Button>
          </Col>
          {/* this button will appear in the PwdAnalysis component only  //Heba*/}
          {tab == 6 && (
            <Col xs="2" lg="2" md="2" className="m-4">
              <Button
                onClick={handleShowAllClick}
                variant="outline-secondary"
                style={btnStyle}
              >
                {showAll ? (
                  <span>{lang.hideAllData}</span>
                ) : (
                  <span> {lang.showAllData}</span>
                )}
              </Button>
            </Col>
          )}
        </Row>
      </>
    );
  };

  return (
    <>
      {!showAll && (
        <Row>
          <Col className="col-4">
            <span style={{ color: "#797979" }}>
              {lang.showing} {pageNumber} {lang.results} {total}
            </span>
          </Col>

          <Col className="col-8">
            <nav aria-label="Page navigation example">
              <ul className="pagination justify-content-center">
                <li className="page-item disabled">
                  <Button
                    variant="outline-primary"
                    onClick={updatePreviousPage}
                    disabled={pageNumber == 1}
                  >
                    <FontAwesomeIcon
                      style={{ cursor: "pointer" }}
                      color="#5fc0e9"
                      size="2x"
                      icon={faChevronRight}
                    />
                  </Button>
                </li>
                <li className="page-item">
                  <Button
                    variant="outline-primary"
                    onClick={updateNextPage}
                    disabled={pageNumber == total}
                  >
                    <FontAwesomeIcon
                      style={{ cursor: "pointer" }}
                      color="#5fc0e9"
                      size="2x"
                      icon={faChevronLeft}
                    />
                  </Button>
                </li>
              </ul>
            </nav>
          </Col>
        </Row>
      )}
      <Row>
        <ToolkitProvider
          keyField="_id"
          // check which data will appear according to showAll state //Heba
          data={showAll ? allData : data}
          columns={columns}
          exportCSV
          classes="table-columns"
        >
          {(props) => (
            <div>
              <BootstrapTable
                striped
                hover
                condensed
                noDataIndication={lang.noData}
                {...props.baseProps}
              />
              <MyExportCSV {...props.csvProps} />
            </div>
          )}
        </ToolkitProvider>
      </Row>
    </>
  );
};
