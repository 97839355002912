import { lang } from "moment";
import { Spinner } from "react-bootstrap";
import logo from "../Assets/imgs/splachLogo.webp";
import { Button } from "./button";
import { TextSubtitle } from "./text";
export function Loading() {
  return (
    <div style={{ height: "100%" }}>
      <Spinner animation="border" variant="primary" />
    </div>
  );
}

export function PageLoading() {
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spinner animation="border" variant="primary" />
    </div>
  );
}
export function SplachPage() {
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <img
        loading="lazy"
        style={{ paddingBottom: 10, width: "150px", height: "150px" }}
        src={logo}
        alt=""
      />
      <Spinner animation="grow" variant="primary" />
    </div>
  );
}
export function ErrorPage({ lang }) {
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <img
        loading="lazy"
        style={{ paddingBottom: 10, width: "150px", height: "150px" }}
        src={logo}
        alt={lang.netwrorkError}
      />
      <TextSubtitle bold>{lang.netwrorkError}</TextSubtitle>
      {/* make the button to backward to the previous page //HEBA */}
      <Button onClick={() => window.history.back()} margin text={lang.update} />
    </div>
  );
}
