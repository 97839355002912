
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { DashTitle, DashSub } from './index'
import { Container } from 'react-bootstrap'

export function Dashboard({ dashboard, lang, sub }) {
    return (
        dashboard &&
        <Container fluid style={{ backgroundColor: '#303546' }} className='dashboard-container'>
            <Container style={{ padding: '1%' }}>
                <DashTitle>
                    {dashboard.title}
                </DashTitle>
                <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                    <a href={dashboard.nav} style={{ padding: 5 }}>
                        <DashSub >
                            {lang.home}
                        </DashSub>
                    </a>
                    <div style={{ padding: 5 }}>
                        <FontAwesomeIcon color='#5fc0e9' size='1x' icon={faChevronLeft} />
                    </div>
                    {sub &&
                        <DashSub sub>
                            {sub.name}
                        </DashSub>
                    }
                    {dashboard.sub &&
                        <DashSub sub>
                            {dashboard.sub}
                        </DashSub>
                    }
                </div>
            </Container>

        </Container>

    )
}

