import React, {
    createContext,
    useContext,
    useEffect,
    useMemo,
    useState,
    useCallback
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SplachPage } from "../Components";
import { AdminLoginApi, AdminUserApi, NotificationsApi, GetMessagesApi, GetContactUsMsgsApi, GetTechProblemsAndSuggestionsApi } from './index'
import io from 'socket.io-client'

const AuthContext = createContext();

export function AuthProvider(props) {
    const { children, lang } = props
    const [user, setUser] = useState(false)

    const [token, setToken] = useState(false)
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [loadingInitial, setLoadingInitial] = useState(true)
    const location = useLocation();
    const navigate = useNavigate()
    const [socket, setSocket] = useState()
    const [notifications, setNotifications] = useState([])
    const [messages, setMessages] = useState([])
    const [contactUsMessages, setContactsUs] = useState([])
    const [techProbAndSuggestionsMessages, setTechProbAndSuggestionsMessages] = useState([])

    const GetNotifications = async () => {
        setNotifications(await NotificationsApi())
    }
    async function GetMessages() {
        setMessages(await GetMessagesApi())
    }
    async function GetContactUsMsgs() {
        setContactsUs(await GetContactUsMsgsApi())
    }
    async function GetTechProblemsAndSuggestionsMsgs() {
        setTechProbAndSuggestionsMessages(await GetTechProblemsAndSuggestionsApi())
    }
    const initHome = async () => {
        setTimeout(() => {
            setLoadingInitial(false)
        }, 1000)
    }

    useEffect(() => {
        if (error) setError(error);
    }, [location.pathname, error]);
    const connectSocket = ({ token, baseUrl, type, id }) => {
        const socket = io(baseUrl, {
            auth: { token, type },
            path: '/socket'
        });
        setSocket(socket)
        socket.on('notifications', () => {
            GetNotifications()
        })
        socket.on(id, (data) => {
            data.msg && GetMessages()

        })
        socket.on('contactUs', () => {
            GetContactUsMsgs()
        })
        socket.on('techProblemsAndSuggestions', () => {
            GetTechProblemsAndSuggestionsMsgs()
        })
    }

    const initAdmin = async () => {
        const SavedToken = sessionStorage.getItem('token') || false
        if (SavedToken) {
            return await AdminUserApi(SavedToken)
                .then((userProfile) => {
                    if (userProfile) {
                        setUser(userProfile)
                        GetNotifications()
                        GetMessages()
                        GetContactUsMsgs()
                        GetTechProblemsAndSuggestionsMsgs()
                        connectSocket({ id: userProfile._id, token: SavedToken, baseUrl: lang.baseUrl, type: 'admin' })
                        navigate('/')
                    }
                })
                .catch((error) => setError(error))
                .finally(() => initHome())
        } else {
            initHome()
            return navigate('/login')
        }
    }

    useEffect(() => {
        initAdmin()
    }, [token])

    const AdminLogin = useCallback((data) => {
        setLoading(true);
        AdminLoginApi(data)
            .then((res) => {
                if (res.success) {
                    Login({ token: res.token })
                }
            })
            .catch((error) => setError(error))
            .finally(() => setLoading(false));
    }, [])

    function Login({ token }) {
        setToken(token)
        sessionStorage.setItem('token', token);
        setLoading(false)
    }

    function logout() {
        navigate('/login')
        setUser(undefined)
        sessionStorage.clear()
    }

    function update() {
        initAdmin()
    }
    const memoedValue = useMemo(
        () => ({
            user, loading, error,
            AdminLogin, update, logout, socket, notifications, messages, GetMessages, contactUsMessages, GetContactUsMsgs,
            techProbAndSuggestionsMessages, GetTechProblemsAndSuggestionsMsgs
        }),
        [user, loading, error, update, logout, AdminLogin, socket, notifications, messages, GetMessages, 
            contactUsMessages, GetContactUsMsgs, techProbAndSuggestionsMessages, GetTechProblemsAndSuggestionsMsgs]
    );
    return (
        <AuthContext.Provider value={memoedValue}>
            {loadingInitial ?
                <SplachPage /> :
                children
            }
        </AuthContext.Provider>
    );
}

export default function useAuth() {
    return useContext(AuthContext);
}
