let testing = false; //Heba
 const testingUrl = 'https://be.npd-egypt.net'
 const baseUrl = 'https://be.npd-egypt.net'
// const baseUrl = "https://127.0.0.1:3000";
//const baseUrl = "https://backend.npd-egypt.net";
//const testingUrl = "https://127.0.0.1:3000";
// const baseUrl = 'https://backend.npd-egypt.net'

export const ar = {
  baseUrl: testing ? testingUrl : baseUrl,
  // home
  update: "تحديث الصفحة",
  login: "تسجيل الدخول",
  register: "إنشاء حساب",
  logout: "تسجيل خروج",
  home: "الرئيسية",
  adminLogin: "الإدارة",
  password: "كلمة المرور",
  username: "اسم المستخدم",
  ctrPanel: "لوحة التحكم",
  ctrRoles: "الأدوار والصلاحيات",
  ctrUsers: "إدارة المستخدمين",
  jobsServices: "الوظائف و الخدمات",
  ctrArticles: "إدارة المحتوى",
  notifications: "الإشعارات",
  contacts: "التواصل",
  reports: "التقارير",
  settings: "إعدادات الحساب",
  pwd: "الباحثين عن عمل",
  companies: "الشركات",
  organizations: "المؤسسات",
  jobRequest: "طلب توظيف",
  job: "وظيفة",
  service: "خدمة",
  article: "مقال",
  video: "فيديو",
  showReports: "عرض التقارير....",
  welcome: "مرحبا بك,",
  userRole: "أنت الآن تدير المنصة بصلاحيات",
  statisticsTitle: "إحصائيات سريعة",
  noData: "لا يوجد بيانات!",
  netwrorkError: "تعذر الاتصال بالخادم!",
  userAccept: "اعتماد العضوية",
  userTerminate: "تعليق العضوية",
  userActivate: "تفعيل العضوية",
  userReject: "رفض العضوية",
  userRejectCancel: "تم رفض العضوية",
  userNew: "مستخدم جديد",
  terminated: "نأسف لقد تم تعليق حسابك",
  currentPass: "كلمة السر الحالية",
  newPass: "كلمة السر الجديدة",
  passConfirm: "تأكيد كلمة السر الجديدة",
  changePass: "تغيير كلمة السر",
  age: "العمر",
  // validations
  usernameRequired: "برجاء إدخال اسم المستخدم",
  usernameError:
    "اسم المستخدم يجب ان لا يقل عن 4 حروف و لا يحتوى على اي رموز مثل (#$@% الخ)",
  usernameLength: "اسم المستخدم يجب ان لا يقل عن 4 حروف ولا يزيد عن 50 حرف",
  emailError: "ايميل غير صحيح",
  phoneError: "رقم غير صحيح",
  passError: "كلمة المرور غير مطابقة",
  passwordError: "كلمة المرور يجب ان تحتوي على حروف وارقام و رموز",
  passwordLength:
    " كلمة المرور يجب ان لا تقل عددها عن 8 حروف ولا تزيد عن 20 حرف",
  passwordRequired: "برجاء إدخال كلمة السر",
  loading: "برجاء الإنتظار...",

  // profiles

  orgProfile: "الملف الشخصي للمؤسسة",

  //     platformManagement
  platformManagement: "إدارة المنصة",
  homePage: "الصفحة الرئيسية",

  // home
  jobs: "الوظائف",
  services: "الخدمات",
  applicants: "المتقدمين للوظائف",
  pages: "الصفحات",
  articls: "المقالات",
  more: "المزيد",
  showMore: "عرض المزيد",
  training: "التدريبات",
  contactUs: "تواصل معنا",
  AddJobBtn: "أضف وظيفة",
  search: "بحث",
  jobDes: "عن أي وظيفة تبحث؟",
  jobLoc: "أين تحب أن تعمل؟",
  jobRank: "ما تصنيف الوظيفة التي تبحث عنها؟",
  jobExp: "ما مدى خبرتك في هذا النوع من الوظائف؟",
  jobSalary: "ما هو الراتب المتوقع والمجزي بالنسبة لك؟",
  jobSearchBtn: "ابحث عن وظيفة الآن",

  startNow: "ابدأ الآن",
  findJob: "ابحث عن وظيفة",
  jobRankTitle: "تصنيفات الوظائف الأكثر شهرة",
  medical: "المجال الطبي",
  acounting: "المحاسبة",
  tech: "التكنولوجيا",
  developers: "التطوير",
  government: "حكومي",
  media: "الإعلام",
  restaurants: "المطاعم",
  allJobs: "كل الوظائف",
  job: "وظيفة",
  recentJobs: "الوظائف المضافة حديثاً",
  featured: "اخترنا لك",
  remotely: "عن بعد",
  partTime: "دوام جزئي",
  fullTime: "دوام كامل",
  keywords: "الكلمات المفتاحية",
  addKeyword: "اضافة كلمة جديدة",
  apply: "تقدم الآن",
  salary: "الراتب:",
  location: "الموقع:",
  topCompanies: "الشركات الأكثر تعييناً",
  exist: "يوجد",
  availableJobs: "وظائف متاحة",
  or: "أو",
  registerEmployeer: "أنا أبحث عن وظيفة",
  registerCompany: "أنا أبحث عن موظفين",
  regDesc:
    "محتوى نصي يمكن تغييره على حسب الحاجة، محتوى نصي يمكن تغييره على حسب الحاجة، محتوى نصي يمكن تغييره على حسب الحاجة، محتوى نصي يمكن تغييره على حسب الحاجة، محتوى نصي يمكن تغييره على حسب الحاجة.",
  regCoBtn: "أنشئ حساب كشركة",
  regEmpBtn: "أنشئ حساب كمتقدم للوظيفة",
  servicesTitle: "نقدم لك أفضل الخدمات",
  serSearch: "البحث عن الوظائف",
  serApply: "التقديم على الوظائف",
  serSecurity: "أمان وخصوصية",
  serAlerts: "إشعارات بالوظائف",
  serPay: "الدفع بسهولة",
  serCall: "خدمة عملاء",
  serDesc:
    "محتوى نصي يمكن تغييره على حسب الحاجة، محتوى نصي يمكن تغييره على حسب الحاجة، محتوى نصي يمكن تغييره على حسب الحاجة.",
  serDeleteMsg: "هل تريد حذف هذه الخدمة؟",
  serHoldMsg: "هل تريد تعليق هذه الخدمة؟",
  editService: "تعديل بيانات خدمة",
  hold: "تعليق",
  serActivateMsg: "هل تريد استئناف هذه الخدمة؟",
  activate: "استئناف",
  cancel: "إلغاء",
  serStay: "الإبقاء عليها",
  advicesTtile: "نصائح مهنية",
  publishDate: "نشر بتاريخ ",
  availableJob: "وظيفة متاحة",
  member: "عضو",
  resume: "سيرة ذاتية",
  company: "شركة",
  bestEmp: "لدينا أفضل الموظفين المحتملين",
  profile: "الملف الشخصي",
  showAllData: "أظهار كل البيانات", //added by Heba
  hideAllData: "اخفاء كل البيانات", //added by Heba
  clients: "ماذا يقول عملاؤنا؟",
  footerEmail: "كن على إطلاع داائم بأحدث أخبارنا وأحدث الوظائف",
  footerEmailInput: "اكتب البريد الإلكتروني الخاص بك",
  footerEmailBtn: "الانضمام للقائمة البريدية",
  footerAboutUs: "اعرف المزيد عنّا",
  footerAppliers: "للباحثين عن وظيفة",
  footerPart: "للشركات والمُعيّنين",
  footerMore: "معلومات أكثر",
  footerMoreBtn: "اعرف المزيد ...",
  copyright: "حقوق الملكية",
  copyrightRequired: "برجاء إدخال حقوق الملكية",
  copyrightError: "حقوق الملكية يجب ان لا يقل عن مقطع",
  // organizations //
  // nav links
  companies: "الشركات",
  organizations: "المؤسسات",
  o_applicants: "المتقدمين",
  ctrPanel: "لوحة التحكم",
  proNotActive: "يرجى إكمال وحفظ ملفك الشخصي",
  profileReview: "ملفك الشخصي قيد المراجعة",
  profileRejected: "نأسف! لم يتم قبول ملفك الشخصي",
  imgBtn: "استعراض الصور",

  services: "الخدمات",
  branches: "الفروع / الهيئات التابعة",
  employment: "التوظيف",
  contact: "التواصل",
  content: "المحتوى",
  updates: "إرسال التحديثات",
  notifications: "الإشعارات",
  favorite: "المفضلة",
  settings: "إعدادات الحساب",

  // profile
  terminated: "نأسف لقد تم تعليق حسابك",
  active: "تمت مراجعة ملفك الشخصي، يمكنك الآن الدخول لكافة مميزات المنصة",
  basicInfo: "المعلومات الأساسية",
  orgName: "اسم المؤسسة",
  country: "البلد",
  province: "المحافظة",
  city: "المدينة",
  state: "الحي",
  address: "العنوان بالتفصيل",
  fullLocation: "العنوان بالخريطة",
  mapBtn: "تعيين على الخريطة",
  field: "المجال",
  entity: "نوع الجهة",
  orgDocuments: "وثائق المؤسسة",
  orgDocBtn: "قم برفع وثائق المنظمة",
  mobile: "الموبايل",
  fax: "الفاكس",
  siteServices: "الخدمات الموجودة بالمكان",
  consultingServices: "خدمات استشارية",
  trainingServices: "خدمات تدريب",
  assessmentServices: "خدمات تقييم",
  empowermentServices: "خدمات تأهيل",
  employmentServices: "خدمات توظيف",
  financingServices: "خدمات تمويل",
  digital: "التواجد الرقمي",
  linkedin: "لينكد ان",
  facebook: "فيسبوك",
  twitter: "تويتر",
  youtube: "يوتيوب",
  instagram: "انستجرام",
  save: "حفظ كل التغييرات",
  sendRequest: "إرسال طلب مراجعة للملف الشخصي",
  saveRequest: "حفظ كل التغييرات وإرسال طلب للمراجعة تلقائياً",
  requestDone: "تم إرسال طلب المراجعة بنجاح",
  bigSize: "اقصى حجم 2MB",
  bigSizeFile: "اقصى حجم 10",
  found: "الملف موجود بالفعل",
  notFound: "لا يوجد",
  Found: "يوجد",
  // services
  addService: "إضافة خدمة جديدة",
  providedServices: "الخدمات المقدمة",
  holdedServices: "الخدمات المعلقة",
  serviceName: "اسم الخدمة",
  serviceFees: "مقابل الخدمة",
  serviceType: "نوع الخدمة",
  availability: "الإتاحة",
  serviceInfo: "وصف الخدمة",
  dsServiceType: "أنواع الإعاقات المقدم لها الخدمة",
  addDs: "أضف إعاقة جديدة",
  serviceProgreses: "إجراءات الحصول على الخدمة",
  addResponsibility: "أضف مسئولية جديدة",
  addProcess: "أضف إجراء جديد",
  showService: "بيانات الخدمة",
  paperNedded: "الأوراق المطلوبة",
  addPaper: "أضف ورقة جديدة",
  add: "إضافة",
  branch: "فرع ",
  applicantsN: "تقدم للخدمة ",
  person: "شخص",
  tsBtn: "تعليق الخدمة",
  actBtn: "استئناف الخدمة",

  edit: "تعديل",
  delete: "حذف",
  showApplicants: "عرض المتقدمين",
  hiddeApplicants: "إخفاء المتقدمين",

  // branches
  addNewBranch: "إضافة فرع جديد",
  addNewCompany: "إضافة شركة جديدة",
  currentBranches: "الفروع المضافة",
  currentCompanies: "الشركات المضافة",
  addBranch: "إضافة فرع / هيئة تابعة",
  branchName: "اسم الفرع/الهيئة",
  editBranch: "تعديل بيانات فرع",
  saveEdit: "حفظ التعديل",
  branchDeleteMsg: "هل تريد حذف هذا الفرع؟",
  removeCompany: "إلغاء الربط",
  cancelCompany: "إلغاء الطلب",
  companyRequest: "إرسال الطلب مرة اخرى",
  companyAccepted: "تم الربط",
  companyPending: "انتظار الموافقة",
  companyCanceld: "تم الغاء الربط",
  companyRejected: "تم رفض الربط",

  deleteCompanyMsg: "هل تريد إلغاء الربط مع",
  link: "ربط",
  back: "العودة",

  //employment
  jobf: "الوظيفة",
  JobactBtn: "استئناف الوظيفة",
  jobActivateMsg: "هل تريد استئناف هذه الوظيفة؟",
  // tabs
  addNewJob: "إضافة وظيفة جديدة",
  currentJob: "الوظائف الحالية",
  terminatedJobs: "الوظائف المنتهية",
  jobDeleteMsg: "هل تريد حذف هذه الوظيفة؟",
  jobTerminateMsg: "هل تريد إنهاء هذه الوظيفة؟",
  terminate: "إنهاء",
  forCompany: "لصالح شركة",

  // content
  jobName: "اسم الوظيفة",
  disType: "نوع الإعاقة",
  jobCountry: "بلد الوظيفة",
  minExp: "أقل سنين خبرة",
  maxExp: "أعلى سنين خبرة",
  minSalary: "الحد الأدنى للراتب",
  maxSalary: "الحد الأعلى للراتب",
  jobField: "مجال الوظيفة",
  jobType: "نوع الوظيفة",
  avType: "نوع الإتاحة الموجودة بالمكان",
  adAv: "أضف نوع إتاحة جديد",
  responsibilities: "المسئوليات",
  reqSkills: "المهارات المطلوبة",
  addSkills: "أضف مهارة جديدة",
  appSteps: "خطوات التقديم",
  addStep: "أضف خطوة جديدة",
  bigVideoSize: "اقصيى حجم 500MP",
  jobN: "تقدم للوظيفة ",
  termateJob: "إنهاء الوظيفة",
  show: "عرض",
  editJob: "تعديل بيانات وظيفة",

  // communication
  textChat: "المحادثات النصية",
  calls: "الإتصال المرئي",
  chatSearch: "البحث عن محادثة",
  writeMessage: "اكتب رسالتك هنا",
  msgError: "تم منع هذه الرسالة",
  msgLength: "الرسالة يجب أن لا تقل عن مقطع ولا تزيد عن 500 حرف",

  // content
  addArticle: "إضافة محتوى كتابي",
  showArticle: "عرض المحتوى",
  addMedia: "إضافة محتوى مرئي",
  contents: "محتوى الإدارة",
  contentsUsers: "محتوى المستخدمين",
  editPost: "تعديل محتوى",
  postDeleteMsg: "هل تريد حذف هذا المقال؟",
  commentDeleteMsg: "هل تريد حذف هذا التعليق",
  articleTitle: "عنوان المقال",
  articleTitleRequired: "قم بإضافة عنوان المقال",
  articleTitleError:
    "عنوان المقال يجب ان لا يقل عن مقطع وان لا يحتوى على اي رموز مثل (#$@% الخ)",
  articleTitleLength: "عنوان المقال يجب أن لا يقل عن مقطع ولا يزيد عن 100 حرف",
  imageDescription: "وصف صورة المقال",
  imageDescriptionRequired: "قم بإضافة وصف صورة المقال",
  imageDescriptionError:
    "وصف صورة المقال يجب ان لا يقل عن مقطع وان لا يحتوى على اي رموز مثل (#$@% الخ)",
  imageDescriptionLength:
    "وصف صورة المقال يجب ان لا يقل عن مقطع وان لا يزيد عن 100 حرف",
  articl: "المقال",
  articleAboutRequired: "قم بإضافة تفاصيل المقال",
  articleAboutError:
    "المقال يجب ان لا يقل عن 4  حروف وان لا يحتوى على اي رموز مثل (#$@% الخ)",
  articleِAboutLength: "المقال يجب أن لا يقل عن 4 حروف ولا يزيد عن 3000 حرف",
  addArtBtn: "إضافة المقال",
  articleImgRequired: "قم بإضافة صورة المقال  ",
  youtubeLink: "قم بإضافة رابط الفيديو من يوتيوب",
  youtubeLinkRequired: "قم بإضافة رابط الفيديو من يوتيوب",
  uploadVideo: "قم برفع الفيديو",
  videoTitle: "عنوان الفيديو",
  videoTitleRequired: "قم بإضافة عنوان الفيديو",
  videoTitleError:
    "عنوان الفيديو يجب ان لا يقل عن مقطع وان لا يحتوى على اي رموز مثل (#$@% الخ)",
  videoTitleLength: "عنوان الفيديو يجب ان لا يقل عن مقطع ولا يزيد عن 100 حرف",
  videoDesc: "وصف الفيديو",
  videoDescRequired: "قم بإضافة وصف الفيديو",
  videoDescError:
    "وصف الفيديو يجب ان لا يقل عن مقطع وان لا يحتوى على اي رموز مثل (#$@% الخ)",
  videoDescLength: "وصف الفيديو يجب ان لا يقل عن 4 حروف ولا يزيد عن 3000 حرف",
  addVideo: "إضافة الفيديو",
  time: "الساعة",
  article: "مقال",
  video: "فيديو",

  // updates
  sendNewUp: "إرسال تحديث جديد",
  savedUpdates: "تحديثات محفوظة لوقت لاحق",
  sendedUpdates: "التحديثات التي تم إرسالها",
  updateTitle: "عنوان التحديث",
  sendTo: "مرسل إلى",
  addTo: "إضافة للمرسل إليهم",
  recipients: "المرسل إليهم", // notes
  serviceDesc: "وصف الخدمة",
  saveTo: "حفظ لوقت لاحق",
  sendUpdate: "إرسال التحديث",
  updateSeen: "تم رؤية التحديث من",
  user: "مستخدم",
  userNote: "حيث لم يتم إرسالة بعد",
  editUpdate: "تعديل بيانات تحديث",
  saveUpdate: "اعتماد التعديل وإرسال التحديث",
  NotificationDeleteMsg: "هل تريد حذف هذا التحديث؟",

  // Notifications
  managements: "الإدارة",
  applicantsS: "الباحثين عن عمل",
  articls: "المقالات",
  // settings
  currentPass: "كلمة السر الحالية",
  newPass: "كلمة السر الجديدة",
  passConfirm: "تأكيد كلمة السر الجديدة",
  changePass: "تغيير كلمة السر",

  username: "اسم المستخدم",
  email: "البريد الإلكتروني",

  password: "كلمة السر",
  passwordConfirm: "تأكيد كلمة السر",
  companyName: "اسم الشركة",

  phone: "رقم الهاتف",
  website: "الموقع الإلكتروني",
  addressInput: "العنوان",
  acceptTerms: "أوافق على",
  terms: "شروط الاستخدام",
  termsDesc: "الخاصة بالموقع واتعهد بتنفيذها",
  haveAccount: "لديك حساب؟",
  signin: "سجل دخول",
  applicant: "باحث عن وظيفة",
  organization: "مؤسسة",

  site: "الموقع",
  cv: "قم بإرفاق السيرة الذاتية",
  cvNotes: "مسموح بصيغ (Pdf,Doc) فقط",
  error: "خطأ في الاتصال",
  rememberMe: "تذكرني",
  notRegister: "ليس لديك حساب",
  registerBtn: "أنشئ حساب",
  loading: "برجاء الانتظار...",
  uploading: "جاري الرفع ",
  saveBtn: "حفظ",
  saveDone: "تم الحفظ",
  notAllowed: "غير مسموح لك بالوصول لهذا الملف",

  choose: "إختر",
  noData: " لا يوجد بيانات!",
  passNotMatch: "تأكيد كلمة المرور غير مطابق",
  netwrorkError: "تعذر الاتصال بالخادم!",
  // .................................................... //

  // pwd //
  //profile
  pwdAnalysis: "تحليل بيانات الباحثين عن العمل",
  pwdJobs: "طلبات التوظيف",
  pwdServices: "طلبات الخدمات",
  pwdAccepted: "المقبولين",
  pwdHired: "تم تعيين",
  informations: "المعلومات",
  relatives: "الأقرباء",
  disabilities: "الإعاقات",
  education: "التعليم",
  proExp: "الخبرة المهنية",
  moreInfo: "معلومات إضافية",
  //
  mainInfo: "الأساسية",
  maininfo: "البيانات الأساسة",
  fullName: "الاسم بالكامل",
  id: "الرقم القومي",
  idFront: "الوجه الأمامي",
  idBack: "الوجه الخلفي",
  idFrontBtn: "رفع الوجه الأمامي",
  idBackBtn: "رفع الوجه الخلفي",
  contactEmeil: "بريد التواصل",
  mobileNumber: "رقم الموبايل",
  dob: "تاريخ الميلاد",
  maritalStatus: "الحالة الاجتماعية",
  sex: "النوع",
  resCountry: "بلد الإقامة",
  pwdState: "المركز",
  fullAddress: "العنوان بالتفصيل",
  nationality: "الجنسية",
  cv: "السيرة الذاتية",
  cvBtn: "قم برفع ملف السير الذاتية",

  qualifications: "التأهيلية",
  qualificationCert: "شهادة التأهيل",
  qualificationBtn: "قم برفع صورة شهادة التأهيل",
  integratedSN: "بطاقة الخدمات",
  integratedBtn: "قم برفع صورة شهادة الخدمات المتكاملة",

  martialAndInsurance: "التجنيدية والتأمينية",
  martialStatus: "الموقف التجنيدي",
  martialEndDate: "تاريخ الإنهاء",
  socialInsuranceStatus: "الموقف التأميني",
  addContact: "إضافة قريب جديد",
  editContact: "تعديل بيانات قريب",
  pwdContacts: "الأقرباء المضافون",
  pwdContactRelation: "صلة القرابة:",
  // pwd contacts
  pwdRemoveContact: "هل تريد حذف ",
  pwdRemoveContact2: "من قائمة أقربائك؟",
  // pwd disabilites
  pwdAddDisability: "إضافة إعاقة جديدة",
  pwdEditDisability: "تعديل إعاقة",
  pwdDisabilityTools: "الأدوات المساعدة والأطراف",
  pwdDisabilityStick: "عصا مساعدة",
  pwdDisabilityChair: "كرسي متحرك",
  pwdHearingaid: "سماعات أذن",
  pwdGlasses: "نظارات مساعدة",
  pwdParalizedTool: "جهاز شلل أطفال",
  pwdArtificialLimp: "طرف صناعي",
  pwdOtherAssistiveTools: "أدوات مساعدة أخرى، يرجى ذكرها وكتابتها",
  pwdLimpPart: "نوع الطرف",
  pwdDisabilites: "الإعاقات المضافة",
  pwdDisabilityType: "النوع التفصيلي",
  pwdDisabilityReason: "سبب الإعاقة",
  pwdReasonDescription: "وصف السبب",
  pwdRemoveDisability: "هل تريد حذف هذه الإعاقة؟",

  // pwd Education
  pwdEdu: "مستوى التعليم",
  pwdEduCertType: "نوع الشهادة ",
  pwdEducert: "الشهادة ",
  pwdDegree: "الشهادة",
  pwdGradDate: "تاريخ التخرج",
  pwdEduPlace: "مكان التخرج",
  // pwd Trainings
  pwdAddTraining: "إضافة تدريب جديد",
  pwdEditTraining: "تعديل التدريب",
  pwdTrainings: "التدريبات المضافة",
  pwdTrainingName: "اسم التدريب",
  pwdTrainingSector: "مجال التدريب",
  pwdTrainingSpeciality: "التخصص",
  pwdTrainingCode: "الكود",
  pwdTrainingCertType: "نوع الشهادة",
  pwdTrainingDate: "تاريخ الحصول",
  pwdRemoveTraining: "هل تريد حذف التدريب؟",

  // pwd ٍُExp
  pwdAddExp: "إضافة خبرة جديدة",
  pwdEditExp: "تعديل خبرة",
  pwdExp: "الخبرات المضافة",
  pwdExpName: "مسمى الوظيفة",
  pwdExpCompany: "جهة العمل",
  pwdExpStartDate: "تاريخ البدء",
  pwdExpEndDate: "تاريخ الانتهاء",
  pwdExpDescription: "التوصيف الرئيسى",
  pwdExpSalary: "المرتب",
  pwdSkills: "المهارات",
  pwdShowSalary: "إظهار المرتب",
  pwdExpDesc: "الوصف",
  pwdRemoveExp: "هل تريد حذف هذه الخبرة؟",

  // pwd job applications
  pwdCurrentApplications: "طلبات التوظيف الحالية",
  pwdOldApplications: "طلبات التوظيف المنتهية",
  pwdProfileSeen: "تم رؤية ملفك الشخصي",
  pwdProfileNotSeen: "لم يتم رؤية ملفك الشخصي",
  pwdJobSuitableStatus: "متطلبات الوظيفة",
  pwdJobSuitable: "مناسب",
  pwdJobSuitableBtn: "مناسب للوظيفة",
  pwdJobNotSuitable: "غير مناسب",
  pwdJobPending: "لم يحدد الموقف",
  pwdJobAccepted: "تم توظيفك",
  pwdJobRejected: "لم يتم توظيفك",
  pwdShowJob: "عرض الوظيفة",
  pound: "ج.م",
  pwdAccept: "توظيف",
  pwdReject: "رفض",

  // pwd services
  pwdGoToServices: "تصفح مقدمو الخدمات",
  pwdShowServiceBtn: "عرض الخدمة",
  pwdServiceMatch: "تصلح لك",
  pwdServiceNotMatch: "لا تصلح لك",
  pwdServiceAccepted: "مقبول",
  pwdServiceRejected: "مرفوض",
  pwdServicePending: "لم يحدد موقفك من الخدمة",
  pwdServiceSuitableStatus: "متطلبات الخدمة",

  // jobs page
  jobSort: "الوظائف حسب التصنيف",
  locationSort: "الوظائف حسب الموقع",
  skillsSort: "الوظائف حسب المهارة",
  salaryMinSort: "الوظائف حد أدنى للمرتب",
  salaryMaxSort: "الوظائف حد أقصى للمرتب",
  jobView: "انت الآن تشاهد",
  jobView2: "من الوظائف",
  jobTypeSort: "الوظائف حسب النوع",
  jobTitle: "اسم الوظيفة",
  noJobs: "لا يوجد وظائف متاحة!",

  experince: "خبرة من",
  to: "إلى",
  years: "سنوات",
  aboutJob: "عن الوظيفة",
  jobTargeting: "الفئات المستهدفة",
  makeCvMandatory: "يجب على المتقدم إدخال السيرة الذاتية الخاصة به",
  registerOrLogin: "تسجيل الدخول",
  notRegisterd: "يجب انت يتم تسجيل الدخول اولاً!",
  jobApplied: "تم التقديم",
  applied: "تم التقديم بالوظيفة",
  pwdJobStatus: "الموقف من الوظيفة",
  pwdProfile: "الملف الشخصي للباحث عن الوظيفة",
  deleteProfile: "حذف العضوية", //Heba
  profilePic: "الصورة الشخصية لـ ",
  deleteProfileConfirm: " تأكيد حذف العضوية",
  cvBtn: "تحميل السيرة الذاتية",
  pwdContact: "التواصل مع الباحث عن العمل",
  orgContact: "التواصل مع المؤسسة",
  coContact: "التواصل مع الشركة",
  pwdPersonalData: "المعلومات الشخصية",

  // services page
  noServices: "لا يوجد خدمات متاحة!",
  aboutService: "عن الخدمة",
  allServices: "كل الخدمات",
  pwdServiceStatus: "الموقف من الخدمة",
  pwdSerProfile: "الملف الشخصي للباحث عن الخدمة",
  serviceSalarySort: "الخدمات حسب المرتب",
  serviceTypeSort: "الخدمات حسب النوع",
  serviceSearchBtn: "ابحث عن خدمة الآن",
  serviceNameSearch: "عن اي خدمة تبحث؟",
  serviceTypeSearch: "ما تصنيف الخدمة التي تبحث عنها؟",
  serviceAmountSearch: "ما هو المقابل المتوقع والمجزي لك؟",
  servicesApplied: "تم التقديم بالخدمة",

  // home page
  homeT1: "الشبكة القومية لخدمات ",
  homeT1N: "تأهيل ",
  homeTC: "ذوي الإعاقة",
  homeT2: "دعم الأشخاص ذوي الإعاقة للوصول إلى فرص أفضل للتطوير الوظيفي",
  homeDesc:
    "عن طريق دعم أصحاب الأعمال لتوظيف الأشخاص ذوي الإعاقة ودعم مقدمو الخدمات للنفاذ إلى الأشخاص ذوي الإعاقة",
  homeFeatures: "دعم ذوي الإعاقة بمميزات عدة!",
  homeF1: "دليل مقدمو الخدمات والخدمات المتاحة.",
  homeF2: "محتوى مرئي للمساهمة في التطوير الوظيفي.",
  homeF3: "محتوى مقروء لإثراء وإثقال مهاراتهم.",
  homeF4: "تسجيل السيرة الذاتية وتوصيات عن التحسينات الممكنة.",
  homeF5: "البحث عن فرص التدريب والتوظيف في شتى المجالات.",
  homeOrgCo: "شركة أو مؤسسة؟",
  homeOrgCoDes:
    "يمكنك إثراء المحتوى عن طريق إضافة المحتوى المرئي والكتابي لدعم ذوي الإعاقة",
  homeOrgCoDes2: "يمكنك نشر التحديثات الهامة لمجموعة معينة من المستخدمين",
  homeOrgCoDes3: "يمكنك أيضاً نشر فرص التوظيف لديك",
  andMore: "والمزيد!",

  // organizations page
  available: "متاحة",
  service: "خدمة",
  orgJobs: "وظيفة متاحة",
  aboutOrg: "عن المؤسسة",
  orgAddress: "عنوان المؤسسة",
  socialMedia: "وسائل التواصل",
  allOrgs: "كل المؤسسات",
  showJobs: "عرض الوظائف",
  showServices: "عرض الخدمات",
  goBack: "متابعة",
  linked: "تم الربط",

  // companies page
  coName: "اسم الشركة",
  test: "",
  // contact us page
  contactUs: "تواصل معنا",
  contactUsTitle: "تواصل معنا على مدار الساعة طوال أيام الأسبوع",
  contactUsNumber: "اتصل بنا على",
  contactUsEmail: "أو راسلنا على البريد الإلكتروني",
  contactUsSendMsg: "اترك رسالة",
  name: "الاسم",
  subject: "موضوع الرسالة",
  msg: "نص الرسالة",
  contactInfo: "بيانات التواصل",
  send: "إرسال",
  //  companies-------
  // profile
  aboutCo: "عن الشركة",
  commercialRegister: "السجل التجاري",
  commercialRegisterBtn: "تحميل السجل التجاري",
  wheelchairElevator: "هل يسمح المصعد باستخدام كرسي متحرك على اختلاف حجمة؟",
  wheelchairBathroom: "مساحة الحمام تكفي لدخول كرسي متحرك وغلق الباب بسهولة؟ ",
  wheelchairOffice: "المساحات بين المكاتب تكفي لتحرك مستخدم الكرسي بسهولة؟",
  entrance: "يرجى ذكر ما هو نوع مدخل الشركة ( مثال: رصيف / سلالم )",
  transportation: "المواصلات",
  transportationAvability: "حالة التوفر",
  transportationType: "نوع المواصلة",
  siteAvailability: "الإتاحة الموجودة بالمكان",
  chairElevator: "يسمح المصعد باستخدام كرسي متحرك على اختلاف حجمة.",
  chairBathroom: "مساحة الحمام تكفي لدخول كرسي متحرك وغلق الباب بسهولة.",
  chairOffice: "المساحة بين المكاتب تكفي لتحرك مستخدم الكرسي بسهولة",
  companyEntrance: "مدخل الشركة",
  noTransportation: "لا يوجد مواصلات",
  coProfile: "الملف الشخصي للشركة",
  allCompanies: "كل الشركات",

  // home articles
  articles: "المقالات",
  articleName: "اسم المقال",
  comments: "التعليقات",
  addComment: "اترك تعليقا",
  commentError:
    "التعليق يجب ان لا يقل عن مقطع ولا يحتوى على اي رموز مثل (#$@% الخ)",
  commentLength: "التعليق يجب ان لا يقل عن مقطع ولا يزيد عن 100 حرف",
  commentRequired: "برجاء ترك تعليق",
  noComments: "لا يوجد تعليقات",
  deleteComment: "إلغاء التعليق",
  // settings
  updateAvatar: "تغير الصورة",
  // roles
  addRole: "إضافة دور جديد",
  currentRoles: "الأدوار الحالية",
  editRole: "تعديل بيانات دور",
  roleDeletMsg: "هل تريد حذف هذا الدور؟",
  adminRoleName: "اسم الشخص",
  adminRoleNameٌRequired: "برجاء إدخال اسم الشخص",
  adminRoleNameError: "اسم المستخدم يجب ان لا يحتوى على اي رموز مثل (#$@% الخ)",
  adminRoleNameLength: "اسم المستخدم يجب ان لا يقل عن مقطع ولا يزيد عن 20 حرف",
  adminRoleRequired: "برجاء تحديد دور المستخدم",
  adminRoleUsername: "اسم الدخول",
  adminRoleUsernameRequired: "برجاء إدخال اسم المستخدم",
  adminRoleUsernameError:
    "اسم المستخدم يجب ان لا يحتوى على اي رموز مثل (#$@% الخ)",
  adminRoleUsernameLength:
    "اسم المستخدم يجب ان لا يقل عن مقطع ولا يزيد عن 50 حرف",
  adminRolePassword: "كلمة سر الدخول",
  adminRolePasswordRequired: "برجاء إدخال كلمة السر",
  adminRolePasswordError:
    "كلمة المرور يجب ان تحتوي على حروف وارقام ولا يقل عددهم عن 8 حروف ",
  adminRolePasswordConfrim: "تأكيد كلمة السر",
  role: "الدور",
  // notifications
  coAndOrg: "الشركات ومقدمو الخدمات",
  noNotifications: "لا يوجد إشعارات!",
  // reports
  from: "من",
  to: "إلى",
  uniqueCode: "كود المؤسسة",
  orgUniqueCode: "أدخل الكود الخاص بالمؤسسة",
  orgUniqueCodeLength: "كود المؤسسة يحتوي علي 13 حرف",
  orgUniqueCodeError: "الكود المدخل لا يطابق المعايير المطلوبة",
  ageIsNumberError: "أدخل أرقام فقط و ليس حروف",
  ageError: "أدخل رقم أكبر من الخانة الأولى للعمر",
  showAll: "عرض الجميع",
  dateError: "يجب ان يكون التاريخ اكبر من التاريخ الذي تم ادخالة",
  all: "الإجمالي",
  exportAsPDF: "تصدير كملف PDF",
  exportAsCSV: "تصدير كملف CSV",
  exportAllAsCSV: "تصدير الكل كملف CSV",
  showAll: "عرض الكل",
  next: "التالي",
  back: "السابق",
  lastPage: "الصفحة الاخيرة",
  firstPage: "الصفحة الأولى",
  showing: "يتم عرض",
  results: "إجمالي",
  registered: "التسجيل بالمنصة",
  created: "تاريخ الإنشاء",
  accountCreationDate: "تاريخ إنشاء حساب",
  status: "الحالة",
  active: "نشط",
  notActive: "غير نشط",
  terminated: "معلق",
  jobProvider: "مقدم الوظيفة",
  reQuery: "تقرير جديد",
  applicantsNumbers: "المتقدمين",
  // managements
  orgAndCo: "الشركات والمؤسسات",
  countries: "الدول",
  addCountry: "إضافة دولة",
  states: "المحافظات",
  addState: "إضافة محافظة",
  disabilites: "الإعاقات",
  addDisability: "إضافة إعاقة",
  type: "النوع",
  addType: "إضافة نوع جديد",
  urlError: "العنوان غير صحيح",
  phoneRequired: "برجاء إدخال رقم الهاتف",
  emailRequired: "برجاء إدخال البريد الإلكتروني",
  fullAddressRequired: "برجاء إدخال العنوان بالتفصيل",
  fullAddressError:
    "العنوان يجب ان لا يقل عن 4 حروف ولا يزيد عن 200 حرف و لا يحتوى على اي رموز مثل (#$@% الخ)",
  faxError: "رقم غير صحيح",

  educations: "التعليم",
  trainings: "مجالات التدريبات",
  addTraining: "إضافة تدريب",
  skills: "الخبرات والمهارات",
  addSkill: "إضافة مهارة",
  cert: "الكلية أو الشهادة",
  addCert: "إضافة كلية أو شهادة",
  addEducation: "إضافة تعليم جديد",
  orgFields: "مجالات الموئسسة",
  coFields: "مجالات الشركة",
  orgTypes: "الجهات",
  addField: "إضافة مجال جديد",
  addType: "إضافة نوع جديد",
  addJob: "إضافة وظيفة",
  mailList: "القائمة البريدية",
  usersMessages: "الرسائل الواردة",
  techProblemsAndSuggestionsMessages: "الدعم الفني",
  workFields: "مجال الوظائف",
  editField: "تعديل مجال الوظيفة",
  toDateError: "تأكد من التواريخ المدخلة",
  toAgeError: "تأكد من الأعمار المدخلة",
  ageIsNumberError: "تأكد أن المدخلات تحتوي على أرقام فقط",
  allStates: "كل المحافظات",
};

export const en = {
  baseUrl: baseUrl,
  login: "Login",
};
