import './css/modal.css'
import { Col, Modal as MD, Row } from 'react-bootstrap'
import { Input, Button } from './index'
import { useState } from 'react'
import Swal from 'sweetalert2'

export function Modal({ show, onHide, title, Body, xl, sub }) {
    return (
        <MD
            show={show}
            size={xl ? 'xl' : "lg"}
            centered
            onHide={onHide}
            scrollable


        >
            <MD.Header style={{ paddingTop: '25px', paddingBottom: '25px' }} closeButton >
                <div className='modal-title'>
                    {title}
                </div>
            </MD.Header>
            <MD.Body style={{ background: sub ? '#79797930' : '' }} >
                {Body && <Body />}
            </MD.Body>
        </MD>
    )
}

export function CreatModal({ show, onHide, title, create, lang }) {
    const [value, setValue] = useState('')

    const onChange = (event) => {
        setValue(event.target.value)
    }
    const click = () => {
        create(value)
        setValue('')
    }
    return (
        <MD
            show={show}
            size="md"
            centered
            onHide={onHide}
            scrollable
        >
            <MD.Header >
                <div className='modal-title'>
                    {title}
                </div>
            </MD.Header>
            <MD.Body >
                <Row>
                    <Col>
                        <Input OnClickEnter={value && click} onChange={onChange} value={value} margin />
                        <Button disabled={!value} onClick={click} fullWidth type='outlined' text={lang.add} />
                    </Col>
                </Row>

            </MD.Body>

        </MD>
    )
}

export function Confirm({ msg, confirmText, active, remove, lang, cancelText }) {

    return Swal.fire({
        customClass: {
            confirmButton: active ? 'confirm-btn-active' : 'confirm-btn',
            cancelButton: 'cancel-btn',
            title: 'confirm-title'
        },
        icon: 'question',
        iconHtml: '؟',
        buttonsStyling: false,
        position: 'center',
        confirmButtonText: confirmText,
        cancelButtonText: cancelText ? cancelText : lang.cancel,
        focusConfirm: false,
        showCancelButton: true,
        title: msg,
    }).then((result) => {
        if (result.isConfirmed) {
            remove()
        }
    })

}

export function LoginRegister({ lang, navigate }) {
    return Swal.fire({
        customClass: {
            confirmButton: 'confirm-btn-active',
            cancelButton: 'cancel-btn',
            title: 'confirm-title'
        },
        icon: 'info',
        buttonsStyling: false,
        position: 'center',
        confirmButtonText: lang.login,
        focusConfirm: false,
        showCancelButton: true,
        cancelButtonText: lang.register,
        title: lang.notRegisterd,
    }).then((result) => {
        if (result.isConfirmed) {
            navigate('/login')
        }
        if (result.dismiss === 'cancel') {
            navigate('/register')
        }
    })
}