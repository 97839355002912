import { countries } from "./data";

export function goTop() {
    var elmnt = document.getElementById("top");
    elmnt.scrollTo({ top: 0, behavior: 'smooth', })
}
export function msgGoTop(type) {
    var elmnt = document.getElementById('msgDiv');
    elmnt && elmnt.scrollTo({ top: elmnt.scrollHeight, behavior: type, })
}
export function sortBy(arr, value) {
    return arr.reduce(function (rv, x) {
        (rv[x[value]] = rv[x[value]] || []).push(x);
        return rv;
    }, {});
}

export function countryByCode(country) {
    const result = countries.find(d => d.value === country) || {label:'آخر'}
    return result.label
}