import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App/index';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App/Assets/theme/app.scss';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
ReactDOM.render(
  <BrowserRouter>
    <div className='main-root'>
      <App />
    </div>
  </BrowserRouter>,
  document.getElementById('root')
);
document.getElementsByTagName('html')[0].setAttribute("dir", "rtl");
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

// reportWebVitals();
