import { ar } from './Assets/Languages'
import Router from "./routes";
import axios from 'axios'

function Main() {
    axios.defaults.baseURL = ar.baseUrl
    return (
        <div>
            <Router lang={ar}  />
        </div>
    );
}

export default Main;