import './css/transitions.css'
import { SwitchTransition, CSSTransition } from "react-transition-group";

export function Fade({nav, children}) {
    return (
        <SwitchTransition mode={'out-in'}>
            <CSSTransition
                key={nav}
                addEndListener={(node, done) => {
                    node.addEventListener("transitionend", done, false);
                }}
                classNames="fade"
            >
                {children}
            </CSSTransition>
        </SwitchTransition>
    )
}
